.tech-heading{
  text-align: center;
}

.element-heading {
  text-align: center;
  color: #000056;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: 500;
  margin-top: 5rem;
  text-transform: capitalize;
  line-height: 37px;
}

.animate {
  animation: bubble-up 1500ms forwards;
}

.element-heading-para {
  color: #000056;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.7;
}

.element-divider-separator {
  background-color: #f28c04;
  margin: auto;
  height: 5px;
  width: 3%;
  display: block;
}

.techies-card {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 236px;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.techies-card:hover {
  transition: 0.3s all ease-in;
  cursor: pointer;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
}

@keyframes bubble-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card-content {
  padding-top: 30px;
  padding-left: 5px;
}

.card-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-left: 16%;
  margin-right: 16%;
  margin-bottom: 2%;
}
.card-container a {
  text-decoration: none;
}

.card-right {
  width: 650px;
}

.sub-title {
  color: #002037;
  margin-top: -13px;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 549;
  text-transform: uppercase;
  line-height: 20px;
}
.sub-title p {
    display: flex;
    justify-content: center;
    font-size: 21px;
    font-family: system-ui;
}

.image-item img {
  width: 37px;
  height: 41px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
li {
  text-decoration: none;
  color: black;
}

.card-content-para {
  color: #002037;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 549;
  text-transform: capitalize;
  line-height: 12px;
  padding-top: 0px;
  margin: 7px;
  margin-right: 0%;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 14px;
}

.image-item {
  flex: -1 0 50%;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}

.card-one {
  /* margin-left: -10%; */
}

.card-fifth {
  justify-content: space-evenly;
  margin: 4%;
  margin-bottom: 3%;
  margin-top: 0%;
}

@media (max-width: 1400px) {
  .card-container {
    margin-left: 3%;
    margin-right: 2%;
  }
}

@media screen and (max-width: 900px) {
}
