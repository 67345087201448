.about-contact-us-main-div {
  background-color: wheat;
  height: 50vh;
  max-width: 90vw;
  margin: auto;
}
.about-us-main-row {
  padding-top: 7rem;
}
.about-us-contact-first-column {
  display: flex;
  justify-content: space-evenly;
}
.about-us-know-more {
  color: black;
  font-weight: 600;
  line-height: 37px;
  font-size: 1.6875rem;
}
.about-us-icons {
  width: 50%;
}
.about-us-icons-image {
  height: max-content;
  width: 15rem;
  margin-top: -2rem;
}
.about-us-icons-image-second {
  width: 63%;
  height: max-content;
  margin-top: -3rem ;
}
.about-us-know-content {
  color: black;
  min-height: 30px;
  line-height: 2.5rem;
}
.about-contact-us-vertical-line {
  border-left: 3px solid white;
  height: 50px;
}
.about-us-join-button {
  width: 10rem;
  height: 49px;
  border: none;
  border-radius: 2rem;
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
}
.about-us-join-button:hover {
  background: white;
  transition: 0.3s all ease-in;
}
.about-us-know-button {
  width: 17rem;
  height: 49px;
  border: none;
  border-radius: 2rem;
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
}
.about-us-know-button:hover {
  background: white;
  transition: 0.3s all ease-in;
}
@media screen and (max-width: 700px) {
  .about-us-contact-first-column {
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }
  .about-us-icons-image-second {
    width: 100%;
    margin-top: 2rem;
    height: max-content;
}
  .about-contact-us-main-div {
    height: -webkit-fill-available;
  }
}
