body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding: 0 !important;
  margin: 0 !important;
  position: initial !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  position: relative !important;
  padding: 0 !important;
  margin: 0 !important;
  /* background-color: red; */
  width: 100%;
}

/* * {
  outline: solid 1px red;
} */
