
.testimonial-main-header {
    flex-wrap: wrap;
    text-align: center;
    width: 100vw;
    /* background-color: rgb(255 205 134); */
    background-color: rgb(255 196 112);

    padding-top:76px;
    padding-bottom: 100px;
    margin-top: 50px;
    
  }
  
  .horzontal-line {
    width: 15rem;
    background-color: black;
 
    border: none;
    margin: 1rem auto;
  }
  
  .testimonial-heading {
    color: black;
  }
  
  .testimonial-word {
    color: black;
    font-size: x-large;
  }
  
  .video-responsive {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  
  .video-responsive iframe {
    width: 50%;
    height: 22rem;
    margin-left: 2rem;
  }
  
  @media screen and (max-width: 768px) {
    .video-responsive {
      flex-wrap: wrap;
    }
  
    .video-responsive iframe {
      width: 100%;
      height: 17rem;
      margin-left: 2rem;
    }
    .testimonial-main-header {
      padding: 1rem;
    }
  }
