.element-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.element-container {
  flex: 1;
  /* margin-left: 13rem; */
}

.qa-section-accordion {
  margin-right: 15rem;
}

.element-row {
  width: 70%;
  margin-left: 30%;
}

.element-row-right {
  width: 100%;
  /* margin-left: 10%; */
}

.left-side-container {
  background-color: #f9f9f9;
  padding: 20px;
}

.home-side-container {
  background-color: #fff;
  padding: 20px;
}

.element-column {
  flex: 1;
  margin-top: 5rem;
}

.element-widget-wrap {
  margin-bottom: 20px;
}

.element-element {
  margin-bottom: -16px;
}

.element-widget-container {
  padding: 3px 5px;
  margin-right: 24%;
  margin-left: -12%;
}

.element-divider {
  height: 1px;
  background-color: rgb(255, 255, 255);
  margin: 20px 0;
}

.qa-divider-separator {
  background-color: #36e2d8;
  height: 5px;
  margin-left: 15%;
  width: 20%;
  display: block;
}

.element-heading-title {
  color: #000056;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin-left: 15%;
  line-height: 60px;
  margin-bottom: -2rem;
}

.element-heading-title-left {
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 550;
  line-height: 35px;
}

.element-icon-box-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.element-icon {
  margin-right: 10px;
  width: 1em;
  height: 2em;
  font-size: 2em;
  color: #14a800;
  position: relative;
  display: block;
}

.element-icon-box-title {
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  padding: 5%;
  color: #434343;
}

.element-image img {
  text-align: center;
  width: 130px;
  height: 60px;
}

.elementor-row {
  display: flex;
  flex-wrap: wrap;
}

.element-container-left {
  margin: 4%;
  flex: 1 1;
  width: 70%;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #f2f7f2;
  border-radius: 9px;
}

.element-column-width {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 950px) {
  .element-row {
    margin-left: 15%;
  }

  .qa-section-accordion {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .element-section {
    flex-direction: column;
  }

  .element-heading-title-left {
    margin-left: 0rem;
  }

  .element-widget-container {
    margin-left: 10%;
  }

  .element-container-left {
    margin: 4%;
    flex: 1 1;
    width: 100%;
    padding-right: 67px;
    padding-left: 0px;
    background-color: #e8edee;
    border-radius: 9px;
  }

  .element-column {
    margin-left: 3rem;
  }

  .element-heading-title {
    margin-left: -18%;
  }

  .accordion-button {
    margin-left: -7%;
  }

  .qa-divider-separator {
    margin-left: 1rem;
  }

  .accordion-body {
    margin-left: -2rem;
    margin-right: 10rem;
  }

  .accordion-header {
    margin-bottom: -9px;
  }

  .element-container {
    margin-left: -2rem;
  }
}

.element-accordion-item {
  margin-bottom: 20px;
}

.accordion-button {
  background-color: #f9f9f9;
  color: #000;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
  width: 70%;
}

.accordion-button:not(.collapsed) {
  font-weight: 500;
  font-size: 17px;
  color: #000;
  background-color: #fff;
  box-shadow: none;
}

.accordion-button .collapsed {
  background-color: #ddd;
  font-weight: 450;
  font-size: 17px;
}

.accordion-button:hover {
  background-color: #ddd;
}

.accordion-body {
  display: block;
  background-color: #fff;
  padding: 10px 20px;
}

.element-accordion-content {
  margin-top: 0px;
  max-height: 0%;
  background-color: #fff;
  color: #686868;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 29px;
  padding: 5px 10px 10px 20px !important;
  overflow: hidden;
}

.element-accordion-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 24px;
  align-items: left;
  display: flex;
  justify-content: space-between;
}

#accordionExample {
  background-color: #000056;
}
.accordion-button:focus {
  z-index: 0;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-button {
  font-weight: 450;
  font-size: 17px;
  color: #000;
}

.collapse.show {
  font-weight: 550;
  font-size: 25px;
  color: #000;
}

.accordion-item {
  border: none;
}

.collapse.show {
  border: none;
}

.home-side-container {
  overflow: hidden;
}

.element-image img {
  width: 100%;
}

.home-side-container section {
  animation: fade-in 1500ms forwards;
}

@keyframes fade-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.left-side-container section {
  animation: fade-in-left 1500ms forwards;
}

@keyframes fade-in-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 500px) {
  .element-row {
    margin-left: 8%;
  }

  .element-container-left {
    width: max-content;
  }
  .element-row-right {
    padding: 50px 0rem;
    margin-left: 6%;
  }

  .element-column {
    margin-left: 0rem;
    margin-top: 0rem;
  }
}

.qa-divider-separator-right {
  width: 7%;
}
