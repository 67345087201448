:root {
  --black: #000;
  --saffron: #ff9933;
}

.ecommerce-container_update {
  font-family: Arial, sans-serif;
  color: var(--black);
  padding: 2rem;
}

/* Development Section */
.development_update {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /* gap: 2rem; */
  /* margin-top: 1rem; */
  padding-top: 76px; 
  
}

.development_left_update {
  flex: 1;
  max-width: 750px;
}

.development_image_update {
  width: 100%;
  border-radius: 8px;
}

.development_right_update {
  flex: 2;
  max-width: 600px;
}

.development_right_update h1 {
  font-size: 2rem;
  color: black;
  margin-bottom: 1.5rem;
}

.cards_update {
  background: white;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.cards_update h3 {
  font-size: 1.25rem;
  color: var(--saffron);
  margin-bottom: 0.5rem;
}

.cards_update p {
  font-size: 1rem;
  color: var(--black);
}


/* ERP Integration Section */
.erp-integration {
  margin-top:3rem;
  max-width: 83rem;
  text-align: center;
  margin: auto; 

  margin-top: 3rem;
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.erp-integration h2 {
  color: var(--black);
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.erp-integration h1 {
  color: var(--saffron);
  font-size: 2rem;
  margin: 1rem 0;
  font-weight: bold;
}

.erp-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.erp-card {
  background: var(--white);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  max-width: 300px;
  text-align: center;
}

.erp-card img {
  width: 60px;
  margin-bottom: 1rem;
}

.erp-card h3 {
  font-size: 1.25rem;
  color: var(--saffron);
  margin-bottom: 0.5rem;
}

.erp-card p {
  font-size: 1rem;
  color: var(--black);
}


.ready-wired {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 60px 10%;
  background-color: #f4f4f4; 
}

.ready-wired-content {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.ready-wired-content h1 {
  font-size: 2.5rem;
  color: #333;
  margin: 0;
}

.wired-highlight {
  color: #ff6600; 
  font-weight: bold;
}

.wired-description {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
}

.wired-btn {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1rem;
  color: #fff;
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.wired-btn:hover {
  background-color: #e55a00;
}

.ready-wired-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
 
}

@media (max-width: 768px) {
  .ready-wired {
    flex-direction: column;
    text-align: center;
  }

  .ready-wired-content {
    max-width: 100%;
  }

  .ready-wired-image {
    margin-top: 20px;
  }
}


/* E-Commerce Service Description Section */
.service_description {
  padding: 5rem 2rem;
  background-color: #f4f4f4;
  text-align: center;
}

.service_description_box {
  max-width: 82.5rem;
  margin: 0 auto;
  padding: 3rem;
  background-color: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.service_description h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
}

.service_description h2:after {
  content: '';
  display: block;
  width: 50px;
  height: 5px;
  background-color: #f39c12;  
  margin: 10px auto;
}

.intro_text {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.7;
  margin-bottom: 3rem;
  font-weight: 300;
}

.service-details {
  display: grid;
      grid-template-columns: 1.2fr 1.2fr 1.2fr 1.2fr;
      gap: 2rem;
}

.service-item {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: left;
  border: 2px solid #f9f9f9; 
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Set fixed height for h3 and p */
.service-item h3 {
  font-size: 1.7rem;
  font-weight: 600;
  color: #F28C04;
  margin-bottom: 1rem;
  height: 30%; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item p {
  font-size: 1rem;
  color: black;
  line-height: 1.7;
  margin: 0;
  height: 70%; 
  overflow: hidden;  
}

/* Hover Effect for the entire service-item */
.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
}



.service-item i {
  font-size: 2rem;
  color: white;
}

@media (max-width: 1024px) {
  .service-details {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .service-details {
    grid-template-columns: 1fr 1fr;
  }

  .service-description-box {
    padding: 2rem;
  }

  .service-description h2 {
    font-size: 2rem;
  }

  .intro-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .service-details {
    grid-template-columns: 1fr;
  }

  .service-description h2 {
    font-size: 1.8rem;
  }

  .intro-text {
    font-size: 0.9rem;
  }
}



.why-choose-us {
  padding: 50px 10%;
  background-color: #f9f9f9;

  
}

.why-choose-us h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  color: #F28C04;
}

.why-choose-us-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.why-item{
  border: 1px solid #ccc; /* Sets border color and thickness */
  border-radius: 10px;
  padding: 5%;
}

.why-item h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}
.why-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .why-choose-us-content {
    grid-template-columns: 1fr;
  }
}






.how-we-do-it {
  padding: 50px 10%;
  background-color: #fff;
}

.how-we-do-it h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.how-we-do-it-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.how-item {
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: transform 0.3s ease;
}

.how-item:hover {
  transform: scale(1.05);
}

.icon img {
  width: 60px;
  margin-bottom: 15px;
}

.how-item h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 10px;
}

.how-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .how-we-do-it-content {
    grid-template-columns: 1fr;
  }
}

.our-promise {
  /* background-image: url("../../../public/images/nclc1.png"); */
  padding: 50px 10%;
  background-color: white; 
  color: #fff;
}

.our-promise h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #F28C04;
}

.promise-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.promise-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.promise-item:hover {
  transform: translateY(-5px);
}

.icon-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #F28C04; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.icon-circle img {
  width: 40px;
}

.promise-text h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #F28C04;
}

.promise-text p {
  font-size: 1rem;
  color: black;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .promise-content {
    flex-direction: column;
  }
}
