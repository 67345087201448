* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.main-container {
  display: flex;
  gap: 4%;
  padding: 0px 10%;
  background-color: white;
  padding-top: 5%;
  padding-bottom: 1%;
}
.three-icon-box-group {
  display: flex;
  gap: 3em;
}
.box-start {
  margin-right: -13%;
  margin-top: -2.5%;
}
.top-gap {
  margin-top: 3%;
}
.trust-icon-box {
  height: 150px;
  width: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235 235 235/37%);
  border-radius: 100%;
  /* margin: 0 auto; */
}
.trust-icon-box-contain {
  text-align: center;
}
.image-box-title {
  margin-bottom: 0;

  font-family: "Poppins", Sans-serif;
  font-size: 14px;

  
  line-height: 22px;
}
.image-box-title a {
  text-decoration: none;
  color: #222;
  font-weight: 500;
}
.new-project-container {
  height: 150px;
  width: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235 235 235/37%);
  border-radius: 100%;
  fill: #f28c04;
  color: #f28c04;
  background-color: #ebebeb5e;
  border: 2px dashed #696969;
  border-radius: 0 0 0 50px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}
.new-project-text {
  color: #f28c04;
}
.new-project-container a {
  text-decoration: none;
  font-weight: 500;
}

.trust-title h3 {
  color: #000056;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
}
.trust-paragraph {
  padding: 3% 0px 6% 0px;
  line-height: 25px;
}
.line-divider {
  padding-top: 17px;
  padding-bottom: 19px;
}



.line-divider-separator {
  width: 40px;
  height: 3px;
  background-color: #f28c04;
}
.outside-border {
  border-style: solid;
  border-width: 1px;
  border-color: #0000001a;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 30px 20px 30px 20px;
  display: flex;
  /* justify-content: center; */
  gap: 7%;
}
.outside-border ul li {
  list-style: none;
}
.trusted-icon-list-item {
  padding-top: 3%;
}
.first-half {
  width: 50%;
}
.trusted-icon-list-icon {
  padding-right: 5.5%;
}
.trusted-icon-list-text {
  font-size: 15.5px;
  font-family: "Poppins", Sans-serif;
  font-weight: 400;
  color: #16222c;
}
.trusted-list-long-text {
  display: flex;
  margin-left: 1%;
  line-height: 1.7rem;
}
.trusted-list-long-text p {
  margin: 0px;
}

.hire-Button:hover {
  /* background-color: #f28c04; */
  color: white;
  background: white;
  transition: 700ms ease-in;
}
.hire-Button {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  width: 180px;
  display: flex;
  justify-content: center;
  gap: 20px;
  letter-spacing: 0.7px;
  fill: #2f2f2f;
  color: #2f2f2f;
  /* background-color: #f9f9f9e3; */
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
  border-style: solid;
  border-width: 1px;
  border-color: #f28c04;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 12px 27px;
  margin-bottom: 8%;
}
.hire-Button a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 1070px) {
  .main-container {
    flex-direction: column;
    padding: 5% 5% 0px 5%;
  }
  .three-icon-box-group {
    gap: 24.5%;
  }
  .box-start {
    margin-right: -28.2%;
    margin-top: -2.5%;
  }
  .second-section {
    padding-top: 6%;
  }
  .first-section {
    padding: 0px 30px;
  }
}
@media (max-width: 1020px) {
  .three-icon-box-group {
    gap: 22.5%;
  }
  .box-start {
    margin-right: -26%;
  }
}
@media (max-width: 1000px) {
  .three-icon-box-group {
    gap: 21.5%;
  }
}
@media (max-width: 950px) {
  .three-icon-box-group {
    gap: 20.5%;
  }
  .box-start {
    margin-right: -24.8%;
  }
}
@media (max-width: 920px) {
  .three-icon-box-group {
    gap: 19%;
  }
  .box-start {
    margin-right: -23%;
  }
}
@media (max-width: 870px) {
  .three-icon-box-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .three-icon-box-group .trust-icon-box {
    margin-top: 3%;
  }
  .box-start {
    margin-bottom: -4.5%;
    margin-right: 14%;
  }
  .new-project-container {
    margin-top: 3.5%;
  }
}

@media screen and (max-width: 768px) {
  .outside-border {
    padding: 30px 20px 20px 0px;
    gap: 0%;
  }
}
.image-box-text{
  padding: 3%;
}

.trusted-icon-list-item:hover {
  transform: none !important; 
  transition: none !important; 
  box-shadow: none !important;
}