body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    overflow-x: hidden;
  }
  .main-content {
    padding-top: 76px; 
  }
  /* Team Section */
  .team-section {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(to bottom, #fdfbfb, #ebedee);
  }
  
  .section-title {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #222;
    animation: fadeIn 1.2s ease-in-out;
  }
  
  .subsection-title {
    font-size: 30px;
    margin: 40px 0 20px;
    color: #444;
    font-weight: 600;
    position: relative;
  }
  
  .subsection-title::after {
    content: '';
    width: 50px;
    height: 4px;
    background: #f28c04;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    animation: slideIn 1s ease-in-out;
  }
  
  .leader-container,
  .team-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Team Card */
  .team-card {
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 15px;
    padding: 20px;
    width: 220px;
    text-align: center;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1),
      -10px -10px 20px rgba(255, 255, 255, 0.5);
    transition: transform 0.5s, box-shadow 0.5s ease;
    animation: zoomIn 1.2s ease-in-out;
  }
  
  .team-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }
  
  .team-image {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    overflow: hidden;
    border-radius: 50%;
    border: 4px solid #f28c04;
    animation: rotateIn 1s ease-in-out;
  }
  
  .team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-card h3 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  
  .team-card p {
    font-size: 14px;
    color: #777;
  }
  
  /* Divider */
  .section-divider {
    border: none;
    border-top: 2px solid #ddd;
    width: 60%;
    margin: 30px auto;
  }
  
  /* Statistics Section */
  .stats-section {
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    /* background: linear-gradient(to bottom right, #1e3c72, #2a5298); */
    /* background: linear-gradient(90deg, #ffa707 0%, rgba(252, 176, 69, 1) 100%); */

    /* background: linear-gradient(90deg, rgb(255 210 147) 0%, rgb(237 176 92) 100%); */
    background: linear-gradient(90deg, rgb(255 149 0) 0%, rgb(237 176 92) 100%);
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .stats-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 40px;
    position: relative;
  }
  
  .stats-title::after {
    content: '';
    width: 80px;
    height: 4px;
    background: black;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    animation: slideIn 1s ease-in-out;
  }
  
  .stats-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .stat-text {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 25px 15px;
    text-align: center;
    flex: 1 1 calc(25% - 30px);
    min-width: 200px;
    max-width: 300px;
    animation: fadeUp 1.5s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .stat-text:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  }
  
  .stat-percentage {
    font-size: 40px;
    font-weight: bold;
    /* color: #ffd700; */
    color: white;
  }
  
  .stat-title {
    font-size: 16px;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes slideIn {
    from {
      width: 0;
    }
    to {
      width: 50px;
    }
  }
  
  @keyframes rotateIn {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .stat-text {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .leader-container,
    .team-container,
    .stats-container {
      flex-direction: column;
      align-items: center;
    }
  
    .stat-text {
      flex: 1 1 100%;
      max-width: 400px;
    }
  
    .stats-title {
      font-size: 28px;
    }
  
    .stat-percentage {
      font-size: 32px;
    }
  }
  
  @media (max-width: 500px) {
    .stats-title {
      font-size: 24px;
    }
  
    .stat-percentage {
      font-size: 28px;
    }
  
    .stat-title {
      font-size: 14px;
    }
  }
  
  
  
  
  /* Awards Section */
  .awards-section {
    text-align: center;
    padding: 80px 20px;
    background: #f5f5f5;
    color: #000000;
  }
  
  .awards-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 50px;
    color: #000000;
    letter-spacing: 2px;
  }
  
  .awards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .award-card {
    background: #ffffff;
    border-radius: 12px;
    width: 280px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .award-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .award-icon-wrapper {
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    /* background: #000000; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1.2s ease;
  }
  
  .award-icon {
    width: 60%;
    height: auto;
  }
  
  .award-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000000;
  }
  
  .award-description {
    font-size: 14px;
    color: #333333;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .awards-container {
      flex-direction: column;
      align-items: center;
    }
  }