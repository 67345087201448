.home-section {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(11, 22, 63, 1) 27%
  );
  flex-wrap: wrap;
}
/* .widget-container-image {
  background-image: url(../../../assets/images/after\ blue\ image.svg);
  background-size: cover;
  width: fit-content;
} */

.home-container {
  align-items: center;
}

.unbeatable {
  color: #f28c04;
}

.home-background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.home-row {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.home-wrapper {
  width: 100%;
  position: relative;
  display: flex;
}

.home-widget-container {
  padding: 80px 35px 0 0;
  height: fit-content;
}

.home-column {
  position: relative;
  min-height: 1px;
  display: flex;
  margin-left: 5%;
  width: 54%;
}

.home-home {
  --widgets-spacing: 20px;
}

.inner-home-section {
  width: 100%;
}

.home-home-top {
  text-align: left;
  width: fit-content;
  padding-left: 6rem;
}

.home-column-wrap {
  width: 100%;
  position: relative;
  display: flex;
}

.home-button {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 21px;
  fill: #2f2f2f;
  color: #2f2f2f;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #f28c04;
  border-radius: 3px;
  padding: 11px 17px;
}

.home-heading-title {
  color: white;
  font-family: "Poppins", Sans-serif;
  font-size: 31px;
  font-weight: 600;
  margin-top: 31%;
  line-height: 59px;
  letter-spacing: -1px;
}

/* .home-heading-title::after {
  content: "";
  display: block;
  width: 225px;
  height: 17px;
  margin-left: auto;
  margin-right: 150px;
  margin-top: 32px;
  margin-bottom: 10%;
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
} */

a {
  text-decoration: none;
}

.home-button:hover {
  background-color: #f28c04;
  transition: 700ms ease-in;
}

section {
  display: block;
}

.home-section-container {
  max-width: 1024px;
  flex-wrap: wrap;
}

.home-section-container-left {
  width: 35.966%;
  position: relative;
}

.home-widget-container-left {
  margin: 0 0 -10px;
}

.home-widget-container-middle {
  margin: 0;
}

.home-widget-container-bottom {
  padding: 20px 0 0;
}

.hire-developer-button {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 19px;
  justify-content: space-between;
  width: 10rem;
  height: 2.5rem;
  color: white;
  border: none;
  border-radius: 3px;
  width: 30%;
  padding: 14px;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    #ffa707 0%,
    rgba(253, 77, 29, 1) 20%,
    rgba(252, 176, 69, 1) 100%
  );
  margin-bottom: 15rem;
}

.arrow-icon:hover {
  color: #000;
}

.hire-developer-button:hover {
  transition: 500ms ease-in;
  background: white;
  color: black;
}

.home-heading-title-left {
  font-size: 35px;
  line-height: 4px;
  color: #f28c04;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
}

.image-container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 650px;
  text-align: center;
  margin-top: 0%;
  margin-bottom: 40%;
  position: relative;
  opacity: 0;
  animation: bubble-up 3000ms forwards;
  margin-left: 20%; */

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  text-align: center;
  /* margin-top: 0%; */
  /* margin-bottom: 40%; */
  /* position: relative; */
  opacity: 0;
  animation: bubble-up 3000ms forwards;
}

.image-container {
  width: 100%;
  height: 80%;
}

.Home-top-banner-image {
  background-image: url("../../../assets/images/world_image.png");
  background-size: cover;
  margin: 77px 71px 34px -12px;
  background-size: cover;
  padding: 77px 17px 25px 35px;
  width: 63%;
}

.image-container:active {
  width: 655px;
  height: 667px;
}

@keyframes bubble-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.home-side-container-animate {
  overflow: hidden;
}

.home-side-container-animate section {
  animation: fade-in 3000ms forwards;
}

@keyframes fade-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.home-heading-title-bottom-left {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 85px;
  letter-spacing: -1px;
}

.home-section-container-middle {
  width: 15.364%;
  position: relative;
}

.home-widget-image-middle {
  vertical-align: middle;
  display: inline-block;
  height: 7rem;
  max-width: 50%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.home-button-text {
  font-family: "Poppins", Sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 21px;
  fill: #2f2f2f;
  color: #2f2f2f;
}

.home-populated {
  padding: 0;
}

.home-align-icon-bottom {
  margin-left: 20px;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.animated {
  animation-duration: 1.25s;
}

@media screen and (max-width: 1600px) {
  .home-widget-container {
    padding: 80px 0px 0 0;
    /* padding: 84px 10px 0px 150px; */
  }
}

@media screen and (max-width: 1500px) {
  .image-container img {
    /* position: absolute; */
    margin-top: 8rem;
    width: 72%;
    height: 64%;
  }
  .home-heading-title {
    color: white;
    font-family: "Poppins", Sans-serif;
    font-size: 31px;
    font-weight: 600;
    margin-top: 40%;
    line-height: 59px;
    letter-spacing: -1px;
  }
}

@media screen and (max-width: 1400px) {
  .home-home-top {
    margin-left: 20%;
    width: auto;
    padding-top: 15rem;
  }

  .image-container {
    margin-bottom: -20rem;
  }

  .hire-developer-button {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .home-wrapper {
    display: flex;
  }

  .hire-developer-button {
    width: 46%;
    margin-left: -22%;
  }

  .home-heading-title-bottom-left {
    font-size: 18px;
  }

  .home-widget-container {
    padding: 0px 6px 0 0;
  }

  .home-section-container-left {
    width: 100%;
  }

  .home-heading-title {
    margin-right: 0%;
    margin-left: -25%;
    margin-top: 26%;
  }

  .image-container img {
    /* margin-left: -8%;
    margin-top: 4%; */
  }

  .image-container-section-column {
    flex-direction: column;
  }

  .home-section-container-left {
    margin-left: 16%;
  }
  .home-column {
    position: relative;
    min-height: 1px;
    margin-left: -10%;
    width: 100%;
  }

  .home-section-container-left {
    margin-left: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .home-row {
    flex-direction: column;
  }

  .image-container img {
    /* position: absolute; */
    margin-top: 5rem;
    width: 31%;
    height: 50%;
  }

  .hire-developer-button {
    width: 28%;
    margin-left: 8%;
  }

  .image-container {
    margin-bottom: 0rem;
  }
  .home-home-top {
    margin-left: 25%;
    width: 73%;
  }

  .home-heading-title {
    margin-right: 0%;
    margin-left: 8%;
    margin-top: 23%;
    line-height: 50px;
    font-size: 29px;
    font-weight: 500;
  }
}

@media screen and (max-width: 900px) {
  .image-container img {
    margin-left: 0%;
  }
}

@media screen and (max-width: 870px) {
  .home-section {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(11, 22, 63, 1) 27%
    );
  }
  .Home-top-banner-image {
    /* background-image: url(http://localhost:3001/static/media/world_image.0f232be….png); */
    /* background-size: cover; */
    margin: 0px 0px 53px 2px;
    padding: 33px 14px 0px 13px;
    width: 63%;
  }

  .home-side-container-animate {
    overflow: hidden;
  }

  .home-widget-container-middle {
    margin-left: -3rem;
  }

  .home-widget-image-middle {
    height: 5rem;
    max-width: 15%;
  }

  .image-container-section-column {
    flex-direction: column;
  }

  .home-side-container-animate section {
    animation: fade-in 3000ms forwards;
  }

  @keyframes fade-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

@media screen and (max-width: 700px) {
  .hire-developer-button {
    margin-bottom: 40%;
  }
  .home-home-top {
    margin-left: 30%;
    margin-top: 71%;
    padding-bottom: 19%;
    width: 68%;
    width: 90%;
    height: 100%;
  }
  .image-container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 71vh;
    margin-left: -6%;
    /* margin-top: -20%; */
    opacity: 0;
    animation: bubble-up 3000ms forwards;
  }

  .home-home-top {
    margin-left: 0%;
    margin-top: -47%;
  }

  .home-heading-title {
    font-size: 25px;
    line-height: 31px;
    color: white;
    padding-top: 0%;
  }

  .hire-developer-button {
    width: 50%;
    font-size: 12px;
    font-weight: 600;
  }

  .image-container img {
    width: -webkit-fill-available;
    height: 49%;
    margin-top: 7rem;
    margin-right: -12%;
    margin-left: 8rem;
  }

  .home-heading-title::after {
    width: 200px;
    height: 15px;
  }

  .home-heading-title-left {
    font-size: 22px;
    line-height: 0px;
  }

  .home-heading-title-bottom-left {
    font-size: 11px;
  }

  .home-widget-image-middle {
    margin-left: 3rem;
  }

  .Home-top-banner-image {
    padding-right: 5%;
  }
}
