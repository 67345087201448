* {
  font-family: Sans-serif;
}

.ai-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.ai-content-dashboard {
  margin-top: 150px;
}
.ai-content-dashboard h5 {
  color: #f28c04;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.ai-content-dashboard h2 {
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.ai-content-dashboard p {
  margin-top: 30px;
  color: #37393c;
  width: 90%;
  margin-bottom: 30px;
  font-family: "Poppins", Sans-serif;
}
.ai-service-list {
  list-style: none;
}

.ai-service-list li {
  color: #37393c;
  font-size: 14px;
  font-family: "Poppins", Sans-serif;
}
.ai-service-list li i {
  color: #f28c04;
  margin-right: 4px;
  font-size: 15px;
}

.ai-image-dashboard {
  width: 100%;
  height: auto;
  margin-top: 100px;
  animation: floatAnimation 3s ease-in-out infinite;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

/* form css */
.ai-service-form {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2f2f2f;
}
.ai-service-form p {
  font-family: Poppins, Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-decoration: underline;
}
.ai-form-input {
  border: none !important;
  border-bottom: 1px solid #e5e5e6 !important;
  width: 100%;
  padding: 0.37rem 0.5rem;
  font-size: 14px;
  margin-top: 1rem;
  color: rgb(65, 64, 64);
}
.ai-form-section {
  margin: 60px 0;
}
.ai-form-section h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f28c04;
  font-weight: 700;
  margin-bottom: 40px;
  font-family: "Poppins", Sans-serif;
}
.accordion-button:focus {
  border-color: none !important;
}

.ai-form-input:focus {
  border-color: #f6f8f9 !important;
  box-shadow: none !important;
  outline: none !important;
}
.ai-accordion .collapse.show {
  font-size: 16px !important;
  font-weight: 400 !important;
}
.ai-form-container {
  width: 100%;
}
.submit-btn {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
  height: 2.4rem;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  background: linear-gradient(
    90deg,
    #ffa707 0%,
    rgba(253, 77, 29, 1) 20%,
    rgba(252, 176, 69, 1) 100%
  );
  transition: 0.3s all ease-out;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.accordion-button:focus,
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important;
  color: #f28c04;
}

.service-card {
  border-bottom: none;
  margin: 10px;
  padding: 30px;
  box-shadow: 0px 10px 50px rgba(158, 158, 158, 0.25);
  border-radius: 10px;
  position: relative;
  transition: transform 0.4s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", Sans-serif;
  & img {
    width: 70px;
  }
}
.service-card:hover {
  transform: translateY(-10px);
}
.why-choose-us {
  margin: 60px 0;
}
.why-choose-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.why-choose-content {
  & h5 {
    color: #f28c04;
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
  }
  & h2 {
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
  }
  & p {
    margin-top: 30px;
    color: #37393c;
    width: 90%;
    margin-bottom: 30px;
    font-family: "Poppins", Sans-serif;
    text-align: justify;
  }
}
.reach-more-audience {
  margin: 60px 0;
  padding: 40px 0;
  width: 100%;
  background-color: transparent;
  background-image: radial-gradient(
    circle,
    rgba(245, 222, 179, 1) 0%,
    rgba(250, 218, 187, 1) 47%,
    rgba(210, 247, 255, 1) 100%
  );
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    margin-bottom: 20px;
    height: 140px;
  }
}
.reach-audience-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: "Poppins", Sans-serif;
  & h5 {
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
  }
}

/* ================ Responsive Css =============== */
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .ai-content-dashboard {
    margin-top: 130px;
  }
  .ai-content-dashboard {
    text-align: center;
  }
  .ai-content-dashboard p {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .ai-service-list li {
    text-align: left;
  }
  .ai-content-dashboard h2 {
    font-size: 22px;
  }
  .ai-image-dashboard {
    display: none;
  }
  .ai-service-form {
    margin-bottom: 40px;
  }
  .why-choose-img {
    margin-bottom: 40px;
  }
  .ai-form-section h1 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .service-card {
    & img {
      width: 50px;
    }
    & h6 {
      margin-top: 6px;
      font-size: 14px;
    }
  }
  .reach-more-audience {
    & img {
      height: 110px;
    }
    & p {
      font-size: 14px;
    }
  }
}
