.App {
  font-family: sans-serif;
  text-align: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  font-family: "Titillium Web", sans-serif;
}

.header-main-div {
  position: fixed;
  color: white;
  background-color: white;
  transition: 0.3s all ease-in;
  width: 100vw;
  z-index: 999;
  height: 76px;
  border: "2px solid red";
}

.header-bg {
  position: fixed;
  color: black;
  width: 100vw;
  z-index: 999;
  height: 76px;
  background: white;
  transition: 0.3s all ease-in;
  border: "2px solid red";
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: fade-inheader 3s forwards;
  opacity: 1s;
}

@keyframes fade-inheader {
  0% {
    opacity: 2s;
    transform: translateY(20%);
  }
  20% {
    opacity: 2s;
    transform: translateY(0);
  }
}

.main-navbar {
  margin: 0 1rem;
  color: black;
  cursor: pointer;
  font-size: 13px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.main-navbar-bg {
  /* margin: 0 1rem; */
  color: white;
  cursor: pointer;
  padding: 16px 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.main-navbar:hover {
  color: #f28c04;
}
.main-navbar-bg:hover {
  color: #f28c04;
}
.nav-btn {
  display: none;
}
.nav-btn-bg {
  display: none;
}
.banner-tagline {
  color: #f36825;
  width: 11rem;
}
.banner-container {
  color: white;
  text-decoration: none;
}
.banner-container:hover {
  color: white;
}
.banner-container-bg {
  color: black;
  text-decoration: none;
}
.banner-container-bg:hover {
  color: black;
}

header .nav-btn {
  cursor: pointer;
  border: none;
  outline: none;
}

header div,
nav {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.hire-button {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 0.7rem;
  justify-content: space-evenly;
  width: 8rem;
  height: 2.5rem;
   /* background-color: #f0a349;  */
  background: linear-gradient(
    90deg,
    #ffa707 0%,
    rgba(253, 77, 29, 1) 20%,
    rgba(252, 176, 69, 1) 100%
  );
  transition: 0.3s all ease-out;
  color: white;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hire-button-bg {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 0.7rem;
  justify-content: space-evenly;
  width: 8rem;
  height: 2.5rem;
  background-color: #f0a349;
  color: #000;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hire-button:hover {
  background-color: white;
  transition: 0.3s all ease-in;
  color: black;
}
.getintouch-btn{
  background: #ffa772;
  text-align: center;
  border-radius: 20px;
  height: 35px;
}




.header-sub-div {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 4rem;
}
.accordions-main-button {
  display: none;
  color:#f28c04;
}
.accordions-button-bg {
  display: none;
}

.heading-first-name {
  display: block;
    padding-right: 12px;
    font-size: 20px;
    color: #000;
    text-decoration: none;
    padding-left: 12px;
}


.heading-first-name:hover {
  color: #f0a349;
}

.Gethover:hover{
  color:white;
}

.dropdown-link {
  color: #f0a349;
  display: block;
  padding-right: 12px;
  font-size: 20px;
  text-decoration: none;
  padding-left: 12px;
}

.dropdown-link:hover{
  color: #f0a349;

}

.dropdown-linksss {
  color: black;
  display: block;
  padding-right: 12px;
  font-size: 20px;
  text-decoration: none;
  padding-left: 12px;
}
.dropdown-linksss:hover {
  color: white;

}
.heading-first-name-bg {
  padding-right: 20px;
  font-size: 20px;
  text-decoration: none;
  color: #000;
}

.heading-first-name-bg:hover {
  color: #000;
}

.heading-other-names {
  font-size: 22px;
}
.link-tags {
  text-decoration: none;
  display: none;
}
.collapse.show {
  font-size: 12px !important;
}

#select-items {
  display: none;
}
.accordions-body {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .heading-other-names {
    font-size: 22px;
    color: #000;
    text-decoration: none;
  }

  .heading-other-names:hover {
    font-size: 22px;
    color: #000;
    text-decoration: none;
  }

  .nav-btn-bg {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin-left: 90%;
    margin-top: -38px;
    border: none;
    align-content: flex-end;
    background: transparent;
    color: #000;
  }
  .nav-btn {
    visibility: visible;
    opacity: 1;
    display: flex;
    flex-direction: row;
    margin-left: 90%;
    margin-top: -39px;
    align-content: flex-end;
    border: none;
    background: transparent;
    color: black;
  }
  #header-navbar-one {
    display: none;
  }
  a #select-items {
    display: block;
    border: none;
  }
  .link-tags {
    text-decoration: none;
    display: block;
  }
  .main-navbar {
    padding: 0;
  }
  .main-navbar-bg {
    padding: 0;
  }

  .heading-first-name {
    display: none;
  }

  .heading-first-name-bg {
    display: none;
  }

  .hire-button,
  .hire-button-bg {
    display: none;
  }
  .accordions-body {
    display: grid;
    font-size: 16px !important;
    justify-content: center;
    background: white;
    width: inherit;
    line-height: 18px;
    padding: 0 !important;
    height: 42rem;
    text-decoration: none;
  }
  .accordions-main-button {
    display: flex;
    padding: 10px;
    width: 100vw;
    background: white;
    font-size: 16px !important;
    border: none;
    border: "1px solid red";
  }
  .accordions-main-button:hover {
    display: flex;
    padding: 10px;
    width: 100vw;
    font-size: 16px !important;
    background-color: #ffae1a;
    color: white;
    border: none;
  }

  .active-button{
    color: #ffae1a
  }

  .accordions-button-bg {
    display: flex;
    padding: 10px;
    background: white;
    width: 100vw;
    font-size: 16px !important;
    border: none;
  }
  .accordions-button-bg:hover {
    display: flex;
    padding: 10px;
    width: 100vw;
    font-size: 16px !important;
    background-color: #ffae1a;
    color: white;
    border: none;
  }

  .collapsing {
    display: none;
  }
  .collapse .show {
    font-size: 12px;
  }
  .industry-options {
    width: 100vw;
    color: black;
    display: flex;
    font-size: 22px;
    height: 4vw;
    justify-content: left;
    padding-left: 8%;
    align-items: center;
  }
  .industry-options:hover {
    background-color: #f28c04;
    cursor: pointer;
    color: white;
    font-size: 19px;
    height: 4vw;
    display: flex;
    justify-content: left;
    padding-left: 4%;
    align-items: center;
    text-decoration: none;
  }

  .main-navbar {
    color: black;
  }
  .main-navbar-bg {
    color: black;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 56px;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    margin-top: 69px;
    background-color: white;
    width: 100vw !important;
  }

  header .close_responsive_nav {
    display: none;
  }

  nav a {
    cursor: pointer;
    background-color: white;
    width: 100vw;
    align-items: center;
    display: flex;
  }
  nav a:hover {
    background-color: #f28c04;
    color: white;
  }

  .mobile-header a {
    text-decoration: none;
    color: #000;
    font-size: 17px;
    font-weight: 100;
    background-color: white;
    margin-left: none;
    padding-left: 0%;
    line-height: 2rem;
  }
}

@media screen and (max-width: 720px) {
  #header-navbar-one {
    display: none;
  }

  .header-sub-div {
    background-color: #fff;
    color: black;
  }

  .header-bg {
    animation: none;
  }

  .banner-tagline {
    color: #f36825;
    margin-left: -3rem;
    width: auto;
    height: 44px;
  }
}




