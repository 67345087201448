.work-main-section {
  margin: 5rem 0;
}

.work-main-title {
  margin: 0;
  color: #000;
  font-weight: 600;
  line-height: 4.1875rem;
  font-size: 3.4375rem;
  margin-bottom: 0.35em;
  margin-bottom: 8px;
}

.work-main-para {
  margin: 0;
  font-weight: 400;
  line-height: 1.1875rem;
  font-size: 1rem;
  padding: 16px 0px 48px 0px;
}

/*Images CSS*/
.work-first-col-image-one img {
  width: 98%;
  height: 250px;
}

.work-first-col-image-two img {
  width: 98%;
  height: 250px;
}

.work-first-col-image-three img {
  width: 98%;
  height: 300px;
}

.work-second-col-image-one img {
  width: 98%;
  height: 275px;
}

.work-second-col-image-two img {
  width: 98%;
  height: 275px;
}

.work-second-col-image-three img {
  width: 98%;
  height: 320px;
}

.work-third-col-image-one img {
  width: 100%;
  height: 300px;
}

.work-third-col-image-two img {
  width: 100%;
  height: 300px;
}

.work-third-col-image-three img {
  width: 100%;
  height: 300px;
}

.work-main-images {
  display: flex;
}

@media screen and (max-width: 768px) {
  .work-main-images {
    flex-direction: column;
  }
}
