.hire-developers-main-section {
  background-color: transparent;
  background-image: radial-gradient(
    circle,
    rgba(245, 222, 179, 1) 0%,
    rgba(250, 218, 187, 1) 47%,
    rgba(210, 247, 255, 1) 100%
  );
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  width: 100%;
  height: 53.125rem  !important;
  padding: 5rem;
}

 .hire-developers-image {
  /* background-image: url(../../../assets/images/taskcraft.jpeg); */
  width: 100%;
  height: 53.125rem;
  /* background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box; */
} 

@media screen and (max-width: 1000px) {
  .hire-developers-main-section {
    height: 1100px;
  }
  .hire-developers-image {
    margin-bottom: 12rem;
  }

  .hire-developers-image {
    height: 1100px;
  }
}

.react-tel-input .selected-flag .flag{
  margin-top: -20px !important;
}



.mt{
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .hire-developers-main-section {
    height: 1200px;
    margin-bottom: 10rem !important;
  }

  .hire-developers-image {
    height: 1200px;
  }
}

@media screen and (max-width: 500px) {
  .hire-developers-image {
    margin-bottom: 22rem;
  }

  .react-tel-input .form-control{
   width: 100% !important;
   margin-left: 10px !important;
  }
}
