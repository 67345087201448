.industries-main-section {
  margin: 6rem 0;
  padding-left: 50px;
  padding-right: 50px;
}

.industries-first-para {
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 3rem;
}

.industries-main-title h2 {
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 80px;
}

/*Form CSS*/
.industries-boxed {
  margin-bottom: 2rem;
}

.industries-boxed label {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  display: inline-block;
  width: 310px;
  padding: 12px 12px 12px 30px;
  border: solid 1px #ccc;
  transition: all 0.3s;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.industries-boxed input[type="radio"] {
  display: none;
}

.industries-boxed input[type="radio"]:checked + label {
  background: rgb(180, 58, 93);
  background: linear-gradient(
    90deg,
    rgba(180, 58, 93, 1) 0%,
    rgba(253, 77, 29, 1) 20%,
    rgba(252, 176, 69, 1) 100%
  );
  color: #fff;
}

.industries-boxed input[type="radio"]:hover + label {
  background: rgb(180, 58, 93);
  background: linear-gradient(
    90deg,
    rgba(180, 58, 93, 1) 0%,
    rgba(253, 77, 29, 1) 20%,
    rgba(252, 176, 69, 1) 100%
  );
  color: #fff;
}

/*Content CSS*/

.industries-sub-title {
  margin: 0;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: auto auto;
  font-size: 12px;
  font-weight: bold;
  color: black;
  font-family: poppins;
  justify-content: space-between;
  align-items: center;
}

.industries-sub-para {
  font-size: 18px;
  color: #333;
  line-height: 30px;
  font-family: poppins;
  margin-top: 30px;
  margin-bottom: 30px;
}

.industries-sub-content li::before {
  position: absolute;
  left: -25px;
  top: 50%;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  border: 2px solid #f28c04;
  content: "";
  transform: translate(-0, -50%);
}

.industries-sub-content li {
  font-size: 18px;
  color: black;
  position: relative;
  font-weight: 600;
  line-height: 35px;
  font-family: "Poppins";
  margin-left: 25px;
}
li:hover {
  transform: none !important; 
  transition: none !important; 
  box-shadow: none !important;
}
.nohover:hover {
  transform: none !important; 
  transition: none !important; 
  box-shadow: none !important;
}