/* General Styles */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.gallery {
  background: white;
  padding: 20px 10%;
}

/* Hero Section */
.hero-section {
  color: black; /* Change text color to black or desired color */
  text-align: center;
  padding: 20px 20px;
  margin-top: 70px;
  border-radius: 8px;
  position: relative;
}

.hero-title {
  font-size: 2.5rem;
  margin: 0;
  display: inline-block;
  padding-bottom: 5px; /* Space between text and underline */
 
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-top: 10px;
}

/* Slider */
.gallery-slider {
  position: relative;
  margin: 10px auto;
  max-width: 900px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.slider-image {
  width: 100%;
  height: 72vh;
  object-fit: cover;
}

.slider-caption {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
}

/* Gallery Sections */
.gallery-section {
  margin-top: 100px;
}

.gallery-section-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Gallery Items */
.gallery-item {
  position: relative;
}

.gallery-image {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.05);
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.overlay-text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1rem;
}

/* Footer */
footer {
  background: #222;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}
/* Slider Buttons */
.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slider-button-left {
  left: 10px;
}

.slider-button-right {
  right: 10px;
}


/* Styles for Company Logos */
.logo-grid {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px; /* Add space below logos */
}

.logo-image {
  max-width: 200px; /* Ensure logos are visible and proportional */
  height: auto;
  object-fit: contain;
  border: none;
  box-shadow: none;
}

/* Existing Gallery Grid for Event Images (Unchanged) */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}
