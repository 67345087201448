.protection-main {
  background-color: transparent;
  background-image: radial-gradient(at center left, #134766 0%, #1c1c3a 100%);
}

.protection-heading {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  margin-left: -3rem;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.8px;
}

.protection-sub-heading-first {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-left: -13rem;
  text-transform: capitalize;
  line-height: 30px;
}

.protection-divider-separator {
  background-color: #36e2d8;
  height: 2px;
  width: 10%;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: block;
}

.protection-first-para {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-indent: 0%;
  margin-top: 1rem;
  margin-left: 2.8rem;
  line-height: 18px;
}

.talk-friend-button {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.7px;
  fill: #2f2f2f;
  color: #2f2f2f;
  background-color: #f9f9f9e3;
  border-style: solid;
  border-width: 1px;
  margin-top: 1rem;
  margin-left: -14rem;
  margin-bottom: 6rem;
  border-color: #00e9ff;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
  padding: 8px 14px;
}

.protection-first-image {
  width: 143px;
  height: 275px;
  margin-left: 3rem;
  margin-right: 2rem;
  margin-top: 1rem;
}

.protection-first-col {
  display: flex;
}

.protection-image-common-content-title {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.protection-first-image-content-title {
  color: #04f3ba;
  margin-left: -4rem;
  margin-top: -8rem;
}

.protection-second-image-content-title {
  color: #00e9ff;
  margin-left: -5rem;
  margin-top: -7rem;
}

.protection-third-image-content-title {
  color: #d5da00;
  margin-left: -1rem;
  margin-top: -8.5rem;
}

.protection-fourth-image-content-title {
  color: #ff8001;
  margin-left: -2rem;
  margin-top: -7rem;
}

.protection-fifth-image {
  margin-bottom: 3rem;
}

.protection-fifth-image-content-title {
  color: #aae000;
  margin-left: -1rem;
  margin-top: -8rem;
}

.protection-first-image-content {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 9px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 14px;
}

.indented-paragraph {
  margin-left: 18px;
  text-indent: -1px;
  padding-left: 0px;
  text-align: justify;
}

.indented-paragraph-first {
  margin-left: -12px;
  text-indent: -1px;
  padding-left: 29px;
  text-align: justify;
}

.protection-trianlge-image {
  width: 80px;
  height: 70px;
}

.talk-friend-button:hover {
  background-color: #00e9ff;
}

@media screen and (max-width: 1300px) {
  .indented-paragraph {
    margin-left: -10px;
    padding-left: 0px;
  }

  .protection-divider-separator {
    margin-left: -0.8rem;
  }
}

@media screen and (max-width: 1200px) {
  .protection-heading {
    margin-left: -5rem;
  }

  .protection-divider-separator {
    margin-left: -4.8rem;
  }

  .protection-sub-heading-first {
    margin-left: -15rem;
  }

  .indented-paragraph {
    margin-left: -76px;
  }

  .talk-friend-button {
    margin-left: -15.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .protection-first-image {
    margin-left: 2rem;
  }

  .protection-divider-separator {
    margin-left: -4rem;
  }

  .indented-paragraph {
    margin-left: -65px;
  }
}

@media screen and (max-width: 1000px) {
  .protection-main {
    margin-right: -1rem;
  }

  .container {
    max-width: 507px;
  }

  .protection-first-col {
    margin-bottom: -6rem;
  }

  .protection-first-image {
    margin-left: 0rem;
    margin-right: 3rem;
    margin-bottom: 4rem;
  }

  .indented-paragraph {
    margin-right: 4rem;
  }
}

@media screen and (max-width: 800px) {
  .protection-second-col-firstsection {
    margin-left: -4.5rem;
    margin-right: 10rem;
  }
}

@media screen and (max-width: 600px) {
  .protection-first-image img {
    display: none;
  }

  .protection-trianlge-image {
    display: none;
  }

  .protection-heading {
    margin-left: -9rem;
    margin-right: -5rem;
  }

  .protection-first-col {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .protection-first-image img {
    display: none;
  }

  .protection-trianlge-image {
    display: none;
  }

  .protection-heading {
    margin-left: -58px;
    margin-right: 0rem;
  }

  .protection-first-col {
    flex-direction: column;
  }

  .protection-sub-heading-first {
    margin-left: -11rem;
    font-size: 25px;
  }

  .indented-paragraph {
    margin-left: -5px;
    margin-right: 0rem;
  }

  .talk-friend-button {
    margin-left: -14.5rem;
    margin-top: 2rem;
    margin-bottom: 9rem;
  }

  .protection-fifth-image-content-title {
    margin-left: -22px;
    margin-top: -15rem;
    margin-right: -6rem;
  }

  .indented-paragraph-first {
    margin-left: -32px;
    text-indent: -1px;
    line-height: 21px;
    padding-left: 28px;
    margin-right: -13rem;
  }

  .protection-first-image-content {
    font-size: 18px;
  }

  .protection-divider-separator {
    margin-left: -7px;
    margin-top: 0.5rem;
  }

  .protection-first-image-content-title {
    margin-left: -81px;
  }

  .protection-second-image-content-title {
    font-size: 25px;
    margin-top: -9rem;
    margin-left: 39px;
  }

  .protection-image-common-content-title {
    font-size: 25px;
    line-height: 24px;
  }

  .protection-third-image-content-title {
    margin-top: -14.5rem;
    margin-left: 17px;
    margin-right: -15rem;
  }

  .protection-fourth-image-content-title {
    margin-left: -3rem;
    margin-top: -14rem;
  }

  .protection-first-para {
    font-size: 20px;
    line-height: 1.5rem;
    padding-right: 8px;
    word-spacing: 1px;
  }

  .protection-third {
    margin-left: 39px;
    margin-right: -18rem;
  }

  .protection-second {
    margin-left: 39px;
    margin-right: -18rem;
  }

  #accordionExample {
    margin-right: -10rem;
    background-color: none;
  }
}
