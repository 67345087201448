.footer-main-section {
  background-color: #2f2f2f;
}

.footer-end-content {
  background-color: #232323;
  /* margin-top: 5rem !important; */
  width: fit-content;
}

.footer-first-logo {
  text-align: center;
  font-size: 35px;
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 3rem;
  margin-left: -26rem;
}

.footer-top-section {
  margin-top: 5rem;
  display: flex;
}

.footer-second-logo {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-align: right;
}

.footer-second-logo img {
  width: 200px;
  height: 100px;
}

.footer-margin {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #474747;
}

.footer-left-heading {
  color: #f28c04;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-left: 2rem;
  text-transform: uppercase;
  line-height: 21px;
  text-align: start;
  margin-bottom: 1rem;
}

.footer-left-body {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
  font-weight: 300;
  width: inherit;
  text-align: left;
  margin-right: 10rem;
  margin-bottom: 3rem;
}

.footer-subcol-left {
  display: flex;
}

.footer-left-subheading {
  margin-bottom: 1rem;
}

.footer-listitems-parent-left {
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 12px;
  color: #fff;
}

.footer-listitems-parent-left:hover {
  color: white;
}

.footer-subcolumn-ul {
  text-align: left;
}

.footer-hire-dev{
  display: flex;
}

.footer-margin-center {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #474747;
}

.footer-subcol-center {
  margin-top: -3rem;
  display: flex;
}

.center-listitems {
  margin-bottom: 5px;
}

.footer-element-divider-column {
  background-color: #f28c04;
  height: 40px;
  width: 5px;
  display: block;
}

/* .footer-column-right {
  display: grid;
  grid-template-rows: repeat(2,1fr);
} */

.for-jobs-text {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 21px;
  margin-left: 1rem;
  margin-right: -2rem;
}

.footer-hr-email {
  text-align: left;
 
}

.footer-first-logo img {
  width: 258px;
}
a:hover + li, li:hover {
  transform: translateX(10px); 
}
.footer-hr-email a {
    color: #fff;
    margin-top: 0rem;
    font-size: 14px;
    display: flex;
    text-decoration: none;
  /* text-decoration: none; */
}

.footer-button-main {
  text-align: left;
}

.footer-button {
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
}
.footer-button a{
  color: black;
  text-decoration: none;
}
.footer-button:hover {
  padding: 10px 20px;
  border: none;
  background: white;
  transition: 400ms ease-out;
}

.market-blogs-text {
  margin-top: 2rem;
}

.footer-right-subheading {
  margin-right: -10rem;
  padding-left: 23px;
  margin-top: 1rem;
}

.icon-value {
  display: flex;
}

.icon-value a {
  color: white;
}

.twitter-icon {
  margin: 10px 10px;
}

.instagram-icon {
  margin: 10px 10px;
}

.linkedin-icon {
  margin: 10px 10px;
}

.youtube-icon {
  margin: 10px 10px;
}

.end-content {
  /* margin: 50px; */
  width: 100vw !important;
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.copyright-text {
  margin-right: 5rem;
}

.privacy-text a {
  color: #fff;
  margin-right: 1rem;
  text-decoration: none;
}

.footer-logo img {
  height: 10px;
  width: 10px;
}

.footer-right-heading {
  margin-top: -14rem;
}

.footer-aboutUs{
  cursor: pointer;
}

.footer-contactUs{
  margin-top: -8rem !important;
  margin-left: 3rem !important;
  display: flex;
  justify-content: center;
}

.footer-element-divider-column-second{
  margin-top:-9rem;
}

.ml{
  margin-left: -2rem !important;
}

.footer-address{
  margin-top: 1rem;
  margin-left: -1rem;
  text-align: center;
  width: 83%;
}

.mt{
  margin-top: -4rem !important;
}

.email-grid{
  display: grid;
  grid-template-rows: repeat(3,1fr);
  grid-template-columns: repeat(2,1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15.5px;
}
.skype-grid{
  display: grid;
  grid-template-rows: repeat(1,1fr);
  grid-template-columns: repeat(2,1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15.5px;
}

.div-1{
  grid-row: 1/2;
  grid-column: 1/2;
}

.div-2{
  grid-row: 2/3;
  grid-column: 1/2;
  margin-top: -136px;
}

.div-3{
  grid-row: 3/4;
  grid-column: 1/2;
  margin-top: -260px;
}

.div-5{
  margin-top: -141px;
}

.div-6{
  margin-top: -258px;
}



.phone-num{
  text-align: left;
  margin: 28px 0px;
}

.skype-num{
  text-align: left;
  margin-top: 20px;
}

.phone-num a {
  color: #fff;
  margin-top: 1rem;
  font-size: 14px;
  text-decoration: none;
}

.skype-num a {
  color: #fff;
  margin-top: 1rem;
  font-size: 14px;
  text-decoration: none;
}

.add{
  margin-left: 14px !important;
}

.contact-col{
  margin-top: 6rem;
}

.for-add-text{
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 21px;

}

.pmt{
  margin-top: -95px;
  margin-left: -2px;
}

.address-margin-top{
  margin-top: -22px;
}

.align-items-center{
  margin-top: 4rem !important;
}



@media screen and (max-width: 1400px){
  .footer-contactUs{
    margin-top: -7.5rem !important;
  }
}

@media screen and (max-width: 1200px){
  .footer-contactUs{
    margin-top: 9rem !important;
  }

  .add{
    margin-left: -26rem;
  }

  .footer-first-logo img {
    margin-left: 10rem;
  }

  .contact-col {
    margin-top: -10.5rem;
}
}

@media screen and (max-width: 1000px){
  

  .footer-contactUs{
    margin-top: 14rem !important;
  }
}

@media screen and (max-width: 900px) {
  

  .footer-contactUs{
    margin-top: 3rem !important;
  }

  .footer-left-heading {
    margin-bottom: 1rem;
    padding-top: 3rem;
  }

  .footer-right-heading {
    margin-top: 0rem;
  }

  .footer-first-logo img {
    margin-left: 12rem;
  }

  .contact-col {
    /* margin-top: -0.5rem; */
    padding: 57px 0px 0px 28px;
    width: initial;
}

.add {
  margin-left: -12rem !important;
}
}

@media screen and (max-width: 768px) {
  .footer-main-section {
    flex-direction: column;
  }

  .footer-subcol-left {
    flex-direction: column;
  }

  .footer-subcol-center {
    flex-direction: column;
  }

  .footer-column-right {
    flex-direction: column;
  }

  .footer-right-heading {
    margin-top: 1rem;
  }

  .footer-button-main {
    margin-bottom: 2rem;
  }

  .footer-end-content {
    /* margin-top: 12rem !important; */
    width: auto;
  }

  .end-content {
    flex-direction: column;
    width: auto;
  }

  .footer-left-heading {
    margin-top: 1rem;
  }

  .footer-second-logo img {
    width: 150px;
    height: 60px;
  }

  .footer-first-logo img {
    margin-left: 25rem;
  }

  .add{
    font-size: 12px;
    margin-left: 0.5rem !important;
     white-space: pre-wrap;
    text-overflow: ellipsis !important;
  }

  .mt {
    margin-top: -7rem !important;
}

.form-control {
    margin-right: 0rem;
    height: 75%;
    width: 100%;
}
}

@media screen and (max-width: 800px){
.form-control {
    margin-right: 0rem;
    height: 75% !important;
}
}


@media screen and (max-width: 500px){
.form-control {
  padding: 1rem 0.7rem !important;
}

.react-tel-input .form-control{
  padding: 0.5px 14px 18.5px 60px !important;
}

.mt-res{
  margin-top: -10rem !important;
}

.add{
  font-size: 13px;
  margin-left: 0.5rem !important;
   white-space: pre-wrap;
  text-overflow: ellipsis !important;
}
}

