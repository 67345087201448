.text-Services {
  color: #000056;
}
.sub-text-services {
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  /* text-transform: capitalize; */
  line-height: 1.7;
}
.service-Section-Header h4 {
  font-size: 34px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 2em;
  text-align: center;
  margin-bottom: 0;
}
.service-Section-Header .sub-text-services {
  text-align: center;
}
.service-Section-Header .service-Divider {
  display: flex;
  justify-content: center;
  padding-top: 17px;
  padding-bottom: 19px;
}

.service-Divider .service-Divider-Separator {
  width: 40px;
  height: 3px;
  background-color: #f28c04;
}
.services-main-card-section {
  justify-content: space-around;
  display: flex;
  grid-template-columns: auto auto auto;
  padding-left: 10rem;
}
.first-column-for-content {
  width: 27%;
}
.whole-first-column {
  flex: 0 0 auto;
  width: auto !important;
  clip-path: polygon(
    100% 0,
    100% 41%,
    90% 51%,
    100% 61%,
    100% 100%,
    0 100%,
    0 0
  );
}
.container-first-column {
  background-image: url("../../../assets/pexels-photo-973506.webp");
  background-size: cover;
  width: fit-content;
  height: 100%;
}
.container-first-column-gradient {
  background-color: transparent;
  background-image: linear-gradient(267deg, #b76a479b 0%, #ada9a66c 181.28%);
  opacity: 1;
  width: max-content;
  height: 100vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-top: 5rem;
  line-height: 40px;
}
.container-first-column-content {
  display: grid;
  align-items: center;
  padding: 24px;
}
.container-first-column-content h3 {
  color: white;
  font-size: 1.5rem;
}
.services-section-content {
  color: white;
  line-height: 1.5;
}
#ec_bg{
  background: rgb(136, 215, 229);
}
.container-second-column {
  display: grid;
  background-color: aliceblue;
  padding: 25px;
  height: auto;
  border-radius: 26px;
  align-content: space-around;
  box-shadow: rgb(98 188 213 / 20%) 0px 2px 8px 0px;
}
.container-second-column:hover {
  background: rgb(136, 215, 229);
  cursor: pointer;
  transition: 700ms ease-in;
  box-shadow: rgb(98 188 213 / 20%) 0px 2px 8px 0px;
}
.content-second-column {
  padding-right: 12rem;
  margin-left: 3rem;
  font-size: 12px;
  display: grid;
  row-gap: 1rem;
}
.columns-of-second-section {
  display: grid;
  grid-gap: 1rem;
}
.service-section-tabs-icon img {
  width: 5%;
  margin-top: 10px;
}
.service-section-tabs-icon_update img {
  width: 30%;
  margin-top: 0px;
}
.service-section-tabs-icon {
  display: flex;
  font-size: 3rem;
}
.service-section-tabs-content {
  margin-top: 10px;
  display: grid;
}
.card-head-test{
margin-top: "-30px";
}
.service-section-tabs-content a {
  text-decoration: none;
  font-size: 14px;
  color: black;
}
.service-section-tabs-content a :hover{
  color: white;
}
.second-column-first-rows {
  column-gap: 5px;
  margin-bottom: 5rem;
}
.first-column-explore-btn {
  color: white;
  /* background: #f67c05; */
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
  border: none;
  border-radius: 30px;
  width: auto;
}
.first-column-explore-btn:hover {
  color: black;
  background: white;
  transition: 700ms ease-in;
}

