.app-container {
  padding-top: 0;
}
.main-content{
  padding-top:76px;
  background-color: white;
}
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(135deg, #e0eafc, #cfdef3);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    color: #333;
  }
  
  .app-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
    background-color: #faeed8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    font-size: 1em;
    color: #555;
    line-height: 2rem;
  }
  
  /* About Section */
  .about-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #ffffff;
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-section h2 {
    font-weight: bold;
    color: #f28c04;
  }
  
  /* Gallery Section */
  .gallery-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    margin-bottom: 40px;
    border-radius: 12px;
  }
  
  .gallery-title {
    font-size: 2.2em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
  }
  
  .gallery-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    transition: transform 0.4s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .gallery-item:hover {
    transform: translateY(-5px) scale(1.04);
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    transition: transform 0.4s ease;
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
    opacity: 0.85;
  }
  
  .gallery-item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F5DEB3;
    color: #fff;
    padding: 12px;
    text-align: center;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s ease;
    font-size: 1.1em;
  }
  
  .gallery-item:hover .gallery-item-title {
    opacity: 1;
  }
  
  /* Featured Projects Section */
  .projects-section {
    text-align: center;
    padding: 40px 20px;
    background-color: white;
    margin-bottom: 40px;
    border-radius: 12px;
  }
  
  .projects-section h2 {
    color: #f28c04;
  }
  
  .projects-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .project-item {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    color: #333;
    min-width: 160px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .project-item:hover {
    transform: translateY(-5px);
  }
  
  /* Testimonials Section */
  .testimonials-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #ffffff;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonials-section h2 {
    margin-bottom: 20px;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .testimonial {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    font-style: italic;
    color: #555;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .testimonial span {
    display: block;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    text-align: right;
  }
  
  /* Contact Section */
  .contact-section {
    text-align: center;
    padding: 40px 20px;
    /* background-color: #F5DEB3; */
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-section h2 {
    font-size: 2em;
    margin-bottom: 15px;
  }
  
  .contact-section p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .contact-button {
    padding: 12px 24px;
    font-size: 1.1em;
    color: #f28c04;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #e6e6e6;
  }
  
  /* Modal */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s ease;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .modal-content img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .modal-title {
    font-size: 1.2em;
    margin-top: 15px;
    text-align: center;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .modal-close:hover {
    color: #ff4d4d;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  /* Testimonials Section */
  .testimonials-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9fafb;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    max-width: 1100px;
  }
  
  .testimonials-section h2 {
    font-size: 2.5em;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
  }
  
  .testimonials-section h2::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #e9be6f;
    margin: 10px auto 0;
    border-radius: 2px;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .testimonial {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    font-style: italic;
    color: #555;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .testimonial p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
  }
  
  .testimonial span {
    display: block;
    font-weight: 600;
    color: #e3b662;
    margin-top: 15px;
    text-align: right;
  }
  
  /* Small details for added elegance */
  .testimonial::before {
    content: open-quote;
    font-size: 2em;
    color: #e4a93c;
    position: absolute;
    top: -10px;
    left: 20px;
    line-height: 1;
  }
  
  .testimonial::after {
    content: close-quote;
    font-size: 2em;
    color: #e3b662;
    position: absolute;
    bottom: -10px;
    right: 20px;
    line-height: 1;
  }
  /* Body */
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(135deg, #e0eafc, #cfdef3);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    color: #333;
  }
  
  .app-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
    background-color: #ffc355;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }
  
  /* Gallery Section */
  .gallery-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    margin-bottom: 40px;
    border-radius: 12px;
    text-align: center;
  }
  
  .gallery-title {
    font-size: 2.5em;
    margin-bottom: 30px;
    font-weight: bold;
    color: #333;
    position: relative;
  }
  
  .gallery-title::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #f28c04;
    margin: 10px auto 0;
    border-radius: 2px;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 20px;
  }
  
  /* Gallery Item */
  .gallery-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
    opacity: 0.85;
  }
  
  /* Title within Gallery Item */
  .gallery-item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 12px;
    text-align: center;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 1.2em;
  }
  
  .gallery-item:hover .gallery-item-title {
    opacity: 1;
  }
  
  /* Responsive and Clean Design Enhancements */
  @media (max-width: 768px) {
    .gallery-title {
      font-size: 2.2em;
    }
  
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .gallery-title {
      font-size: 1.8em;
    }
  
    .gallery-grid {
      grid-template-columns: 1fr;
    }
  }
 
.app-container {
  padding-top: 0px; 
}
