.about-us-main-div {
  background: #f5deb3;
  height: 100vh;
  /* align-items: center;
  display: flex; */
}
.main-content-about-us-row {
  padding: 4rem;
}
.first-column-first-div {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  gap: 1.5rem;
}

.about-us-heading {
  color: black;
  font-size: 2.5rem;
  margin-left: 5.1875rem;
  font-weight: bold;

}
.about-us-content {
  color: black;
  width: 85%;
}
.about-us-join-us-button {
  width: 10rem;
  height: 49px;
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
  border: none;
  border-radius: 2rem;
}
.about-us-join-us-button:hover {
  background: white;
  transition: 0.3s all ease-in;
}
.about-us-image {
  width: 100%;
}
.about-us-second-main {
  width: fit-content;
}
@media only screen and (max-width: 900px) {
  .first-column-first-div {
    width: 90vw;
  }
  .about-us-content {
    font-size: 2rem;
  }
  .about-us-heading {
    font-size: 2rem;
}
  .main-content-about-us-row {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .about-us-second-main img {
    width: auto;
    height: 40vh;
  }
}
