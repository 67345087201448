.services-container {
  padding: 40px;
  text-align: center;
  background-color: #f4f7fc;
}
p {
  font-size: 0.85rem; 
}

h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
}

.services-container img {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.7;
  max-width: 800px;
  margin: 0 auto 40px;
}

.services-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.service-item {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 100%;
  overflow: hidden;
  max-width: 350px;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.service-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-content {
  padding-top: 15px;

}

.service-item h3 {
  margin-top: 25px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #f28c04;
  text-transform: capitalize;
}

.service-item p {
  font-size: 1rem;
  color: #777;
  line-height: 1.6;
  margin-bottom: 20px;
}

.service-details p {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.6;
}


@media (max-width: 1024px) {
  .services-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, 1fr); 
  }

  .service-item {
    max-width: 500px;
  }
}

@media (max-width: 480px) {
  .services-list {
    grid-template-columns: 1fr; 
  }

  .service-item {
    max-width: 100%;
  }

  .service-image {
    height: 150px; 
  }
}

.service-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.choose-us {
  background-color: #f4f4f4;
  padding: 40px 20px;
  text-align: center;
}

.choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 280px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.card h3 {
  font-size: 1.8rem;
  color: #f28c04;
  margin-bottom: 15px;
}

.card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%;
    margin-bottom: 20px;
  }
}

.choose-us {
  background-image: url('https://img.freepik.com/free-photo/lifestyle-different-emotions-leisure-activities-concept-determined-smiling-female-student-pointing-f_1258-106381.jpg?t=st=1732096076~exp=1732099676~hmac=d742924de4291e4aa8d4c4042137f52865a87e755a1af25c9f51423f45064751&w=1380');
  background-size: cover;
  background-position: center center;
  padding: 40px 20px;
  text-align: center;
  color: white;
}

/* Service Partners Section */
.service-partners {
  padding: 50px 0;
  background-color: #e4e0e0;
  text-align: center;
  margin: 2px;
}

.partners-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  /* padding: 70px; */
  /* padding-bottom: 0px; */
}

.partners-container img{
  max-width: 150px;
  margin: 40px;

}
.partners-container .partnerlogo-2{
  max-width: 110px;
  margin: 40px;
  
}
.partner {
  display: inline-block;
  text-align: center;
}

.partner img {
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
}

h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.partners-description {
  margin-top: 30px;
  font-size: 16px;
  color: #555;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 1024px) {
  .partners-container {
    justify-content: space-between; 
  }
}

@media (max-width: 768px) {
  .partners-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .partners-container {
    flex-direction: column;
    align-items: center; 
  }

  .partner img {
    max-width: 120px; 
  }
}

.about-us-content{
  margin-left: 8%;
}

.about-us {
  padding: 100px 20px;
}

.about-us-content {
  padding: 20px;
}

.about-us h2 {
  font-size: 24px;
}

.about-us p {
  font-size: 16px; 
}

@media (min-width: 768px) {
  .about-us {
    padding: 150px 40px;
  }

  .about-us h2 {
    font-size: 32px; 
  }

  .about-us p {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .about-us {
    padding: 200px 60px;
  }

  .about-us-content {
    max-width: 800px;
  }

  .about-us h2 {
    font-size: 36px;
  }

  .about-us p {
    font-size: 20px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
}

h3:hover {
  color: #F28C04; /* Change text color on hover */
  transform: translateY(-5px); /* Move the text up */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; /* Smooth transition */
}