.cx-protection-main-section {
  margin-top: 15rem;
  background-color: #020202;
}

.cx-protection-text-content {
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-family: "Poppins", Sans-serif;
  line-height: 34px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.cx-protection-first-text {
  margin-left: 8rem;
}
.cx-protection-second-main-div{
  padding: 2rem;
}

.cx-protection-second-text {
  margin-left: 0rem;
  margin-right: 5rem;
}

.cx-protection-third-text {
  margin-right: 18rem;
}

.cx-protection-fourth-text {
  margin-left: 22rem;
}

.cx-protection-fifth-text {
  margin-left: 6rem;
}

.cx-protection-sixth-text {
  margin-left: 0rem;
}

.cx-protection-title {
  padding-top: 1.5rem !important;
}

.cx-protection-title-content {
  text-align: center;
  color: #fff;
  font-size: 40px;
  font-family: "Poppins", Sans-serif;
  line-height: 34px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1400px) {
  .cx-protection-second-text {
    margin-right: 0rem;
  }

  .cx-protection-third-text {
    margin-right: 16rem;
  }

  .cx-protection-fourth-text {
    margin-left: 18rem;
  }

  .cx-protection-fifth-text {
    margin-left: 3rem;
  }

  .cx-protection-sixth-text {
    margin-left: -2rem;
  }
}

@media screen and (max-width: 1200px) {
  .cx-protection-text-content {
    font-size: 20px;
  }

  .cx-protection-second-text {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .cx-protection-text-content {
    font-size: 14px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .cx-protection-first-text {
    margin-left: 5rem;
  }

  .cx-protection-third-text {
    margin-right: 10rem;
  }

  .cx-protection-fourth-text {
    margin-left: 12rem;
  }
}

@media screen and (max-width: 800px) {
  .cx-protection-main-section .cx-image-container {
    display: none;
  }
  .cx-protection-second-main-div{
    padding: 0;
  }


  .cx-protection-first-text {
    margin-left: 0rem;
  }

  .cx-protection-second-text {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .cx-protection-third-text {
    margin-right: 0rem;
  }

  .cx-protection-fourth-text {
    margin-left: 0rem;
  }

  .cx-protection-fifth-text {
    margin-left: 0rem;
  }

  .cx-protection-sixth-text {
    margin-left: 0rem;
  }

  .cx-protection-text-content {
    font-size: 18px;
    line-height: 28px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
