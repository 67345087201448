.hire-form-container {
    margin-top: 3rem;
  }
  
  .hire-developer-card {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 3rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
  
  .hire-developer-right-title {
    color: #2f2f2f;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 15px;
    margin-bottom: 3rem;
    position: relative; /* Ensures the child can be positioned relative to this container */
    text-align: center; /* Center-aligns the text and the divider */
  }
  .hire-developer-divider-separator {
    display: inline-block; /* Makes the separator flexible */
    background-color: #f28c04;
    height: 3px;
    width: 25%; /* Adjust as needed for the desired width */
    margin: 0 auto; /* Centers the separator */
    margin-top: 20px;
  }
  
  .hire-developer-divider {
    position: absolute;
    top: 0; /* Aligns the line above the text */
    left: 50%;
    transform: translateX(-50%);
    width: 65%; /* Ensures the divider spans the width of the container */
  }
  .hire-developer-form-container {
    display: flex;
  }
  
  .hire-developer-form-control {
    height: 40px;
    margin-bottom: 1rem;
  }
  
  .form-check label {
    border: none;
    height: 20px;
    width: max-content;
    padding: 2px 12px;
    color: #37393c;
    font-size: 13px;
  }
  
  .hire-developer-radio {
    width: 100%;
    padding-left: 2rem;
  }
  
  .hire-form-check {
    margin-top: 1rem;
    margin-left: -2rem;
    border: none;
    height: 20px;
    padding: 0;
    text-align: left;
    color: #9097a1;
    font-size: 14px;
  }
  .form-check {
    display: flex !important;
  }
  .form-check-input {
    border: 2px solid gray !important;
  }
  
  .hire-developer-textarea {
    margin-top: 2rem;
    margin-bottom: 0px;
    max-height: 300px;
    overflow: hidden;
  }
  
  .input-group {
    margin-bottom: -22px;

  }
  .close-icon {
    position: absolute;
    right: 8px; /* Adjust the right value to position the icon as desired */
    top: 50%;
    cursor: pointer;
  } 
  
  .hire-developer-textarea-label {
    margin-top: 0.5rem;
    margin-left: 17.5rem;
    font-size: 11px;
  }
  
  .hire-developers-button {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 0px;
    color: #fff;
    background: linear-gradient(
      90deg,
      #ffa707 0%,
      rgba(253, 77, 29, 1) 20%,
      rgba(252, 176, 69, 1) 100%
    );
    box-shadow: 0px 4px 33px 0px rgb(0 0 86 / 28%);
    border-radius: 0;
    padding: 11px 20px;
    display: flex;
    align-items: center;
  }
  
  .hire-devs-arrow-icon {
    color: #fff;
    padding-left: 1rem;
  }
  
  .hire-devs-button {
    padding-left: 10rem;
  }
  
  /*Form Validation errors*/
  .hire-developer-error {
    color: rgb(196, 86, 86);
    margin-top: -1rem;
    font-size: 14px;
    margin-left: 0rem;
    margin-right: 2rem;
  }
  
  .hire-developer-email-error {
    margin-left: -12px;
  }
  
  .hire-developers-button:disabled {
    background: rgb(228, 166, 127);
    width: 14rem;
    justify-content: center;
    display: flex;
    cursor: not-allowed;
    border: none;
    height: 54px;
  }
  
  .hire-developer-textarea-error {
    color: rgb(196, 86, 86);
    margin-top: -1rem;
    font-size: 14px;
    margin-left: 0rem;
    margin-right: 20rem;
    margin-bottom: 2rem;
  }
  
  .hire-developer-mobile-error {
    color: rgb(196, 86, 86);
    margin-top: -1rem;
    font-size: 14px;
    margin-left: 0rem;
    margin-right: 3rem;
  }
  
  .hire-developer-card-spinner {
    border: 5px solid #f3f3f3;
    border-top: 6px solid rgb(223, 135, 80);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
    animation: spin 2s linear infinite;
  }
  
  .Toastify__toast-container {
    top: 20% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .ph-in input {
    width: 254px !important;
  }

  .mt-input {
    margin-top: -0.6rem;
}
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .talk-to-a-friend {
    color: white;
  }
  
  @media screen and (max-width: 1300px) {
    .hire-developer-textarea-label {
      margin-left: -11rem;
    }
  
    .hire-developer-divider-separator {
      margin-left: 12rem;
    }
  
    .hire-devs-button {
      padding-left: 8rem;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .hire-developer-textarea-label {
      margin-left: -6rem;
    }
  
    .hire-developer-divider-separator {
      margin-left: 9rem;
    }
  
    .hire-devs-button {
      padding-left: 3rem;
    }
  
    .hire-developer-form-container {
      display: block;
    }
  }
  
  @media screen and (max-width: 800px) {
    .hire-developer-textarea-label {
      margin-left: -5rem;
    }
    .form-check {
      display: flex !important;
    }
    .hire-developer-radio {
      margin-left: 1rem;
    }
  
    .hire-form-check {
      margin-left: -1rem;
    }
  
    .form-control {
      margin-right: 0rem;
      height: 100%;
      width: 100%;
    }
  
    .hire-developer-form-container {
      flex-direction: block;
    }
  }
  
  @media screen and (max-width: 500px) {
    .hire-developer-textarea-label {
      margin-right: 8rem;
      margin-left: -6rem;
    }
  
    .hire-form-check {
      margin-left: -0.5rem;
    }
  
    .hire-developer-radio {
      width: 100%;
      padding-left: 1rem;
    }
  
    .hire-devs-button {
      padding-left: 1rem;
    }
  
    .hire-developers-button {
      padding: 8px 5px;
    }
  
    .hire-developer-form-container {
      flex-direction: block;
    }
  
    .hire-developer-mobile-error {
      margin-right: 3rem !important;
      margin-top: -1rem;
    }
  
    .hire-developer-textarea-error {
      margin-bottom: 2rem !important;
      margin-top: -0.5rem !important;
      margin-left: -3rem !important;
      margin-right: 14rem !important;
    }
  
    .hire-developer-error {
      margin-right: 1rem;
    }
  
    .hire-developer-form-control {
      height: 40px;
      margin-right: -3rem !important;
      margin-left: 0.5rem;
    }
  
    .hire-developer-textarea {
      margin: 2rem -11rem 0 4rem;
      position: relative;
    }
  
    .hire-developer-divider-separator {
      margin-left: 7rem;
    }
  }
  