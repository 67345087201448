/* Shadow Box Styling */
.shadow-box {
  margin: 40px auto;
  padding: 30px;
  max-width: 80%;
}

/* Container Styling */
.terms-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  Headings
  .terms-container h1 {
    font-size: 2rem;
    color: #222;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    font-size: 1.5rem;
    color: #444;
    margin-top: 20px;
  }
  
  /* Paragraphs */
  .terms-container p {
    line-height: 1.6;
    margin: 10px 0;
    font-size: 1rem;
  }
  
  /* List Styling */
  .terms-container ul {
    margin-left: 20px;
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .terms-container ul li {
    margin: 5px 0;
  }
  
  /* Horizontal Rule Styling */
  .terms-container hr {
    border: 0;
    height: 1px;
    background: #ddd;
    margin: 20px 0;
  }
  
  /* Buttons (Optional if needed) */
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .terms-container {
      margin: 10px;
      padding: 15px;
    }
  
    .terms-container h1 {
      font-size: 1.8rem;
    }
  
    .terms-container h2 {
      font-size: 1.3rem;
    }
  
    .terms-container p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .terms-container {
      padding: 10px;
    }
  
    .terms-container h1 {
      font-size: 1.5rem;
    }
  
    .terms-container h2 {
      font-size: 1.2rem;
    }
  
    .terms-container p {
      font-size: 0.9rem;
    }
  }