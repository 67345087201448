.blog-page-container {
  display: grid;

  grid-template-columns: 1fr 2fr; /* Sidebar:Content */
  gap: 10px;
  background:white;
  padding-left: 50px;
  padding-right: 50px;

}
.container{
  max-width: 100%;
  /* background-color: white; */
}
.sidebar {
  margin-top: 100px;
  padding: 5px;

}

.recent-posts-title{
  font-size: 1.3rem;
  font-weight: bold;
  position: relative;
}

.recent-posts-title::after {
  content: '';
  display: block;
  width: 25%;
  height: 3px;
  background-color: currentColor;
  margin: 0 auto;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 5px;
  padding-left: 20px;

  overflow-y: auto; /* Enables scrolling for the content */
  max-height: calc(100vh - 100px); /* Adjust height to fit within the viewport */
}

/* Scrollbar customization for the content */
.content::-webkit-scrollbar {
  width: 4px;
}

.content::-webkit-scrollbar-thumb {
  background-color: #c0c0bf;
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
.blog-selected-img {
  margin-top: 20px;
  width: 80%;
  align-self: center;
}
.blog-date {
  color: rgb(0 0 0);
  font-size: 15px;
  margin-top: 20px;
  font-weight: 500;
}
.blog-list-container {
  padding: 0 60px;
  margin: 0;
  text-align: justify;
}
.blog-small-img {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 100%;
}
.blog-selected-img {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.sidebar-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & i {
    color: black;
    margin: 0 10px;
  }
}
.blog-content {
  margin-top: 40px;
  color: black;
  padding: 0 60px;
  text-align: justify;
}
.blog-title {
  cursor: pointer;
  color: black;
  font-weight: 600;
  font-size: 1.3rem;
  text-align: justify;

}
.blog-lists {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.blog-list-icon {
  color: #f28c04;
  margin-right: 4px;
  font-size: 15px;
}
.sidebar {
  /* 
  padding: 30px;
  max-height: 1132px;
  overflow-y: auto;
  background: rgb(255 217 162);
  text-align: center;
  border-radius: 10px; */


  position: sticky;
  top: 0; 
  height: 100vh; 
  overflow-y: auto; 
  margin-top: 100px;
  padding: 30px;
  background: rgb(255 196 112);
  text-align: center;
  border-radius: 10px;
}
.blog-short-content {
  font-size: 12px;
  color: gray;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #c0c0bf;
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

.sidebar-item{
  margin-top: 30px;
}

/* Responsive css */
@media screen and (max-width: 768px) {
  .blog-content {
    font-size: 12px;
  }
  .blog-lists li {
    font-size: 12px;
  }
}

@media screen and (max-width: 556px) {
  .blog-page-container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .sidebar {
    margin-top: 0;
    padding: 10px;
    max-height: none;
    overflow: visible;
  }

  .content {
    margin-top: 0;
    padding: 10px;
  }

  .sidebar-item {
    margin-bottom: 10px;
  }

  .blog-title {
    font-size: 16px;
  }

  .blog-date {
    font-size: 14px;
  }

  .blog-small-img {
    width: 100%;
    height: auto;
  }

  .blog-selected-img {
    width: 100%;
    height: auto;
  }

  .blog-list-container {
    padding: 0 15px;
  }

  .blog-lists {
    flex-direction: column;
  }

  .blog-list-icon {
    margin-right: 10px;
  }

  .sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sidebar-item h6 {
    margin-bottom: 10px;
  }

  .sidebar-item p {
    font-size: 14px;
  }

  .blog-short-content {
    font-size: 14px;
  }

  .blog-title-large {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .blog-date {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .blog-page-container {
    padding: 10px;
  }

  .blog-title-large {
    font-size: 22px;
  }

  .blog-date {
    font-size: 12px;
  }

  .blog-list-icon {
    font-size: 12px;
  }

  .sidebar-data i {
    margin: 0 5px;
  }
}
