.hire-developer-title-left {
  color: #f28c04;
  text-align: left;
  font-family: "Poppins", Sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 50px;
  margin: 3rem 0rem;
}

.hire-developer-left-body {
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  margin-bottom: 3rem;
}

.hire-developer-left-subbody {
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}
