.contactus-main-section {
  height: 100vh;
  background-color: #f5deb3;
}

.contactus-container {
  display: flex;
  justify-content: space-between;
  padding-top: 6rem;
}

.contactimg img {
  margin-top: 5.67%;
  width: 80%; 
  height: auto; 
  margin-right: 0; 
}


.contactus-left-button {
  flex-grow: 1;
  order: 10;
  display: inline-block;
  transform: translateY(-50%);
}

.contactus-button-left-text {
  font-family: "Poppins", Sans-serif;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 20px;
  display: flex;
  align-items: center;
  fill: #fff;
  color: #fff;
  background: linear-gradient( 90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100% );
  border-style: solid;
  border-width: 1px;
  margin-right: 5px;
  border-color: #f28c04;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
  padding: 10px 20px;
}

.contactus-form {
  align-items: center !important;
  margin-right: -15rem;
  margin-left: -15rem;
}

.contactus-title-right {
  color: #f28c04;
  margin-bottom: 2rem;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 51px;
}

.contactus-divider-separator {
  background-color: #f28c04;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 3px;
  width: 6%;
  display: block;
}

.form-control {
  border: none !important;
  height: 50px;
  width: -webkit-fill-available !important;
  padding: 0;
  margin-bottom: 1rem;
  border-bottom: solid 1px #a3a3a3 !important;
  border-radius: 0px !important;
}
#textareaid{
  height: 100px;
}

.msg-form-control {
  border: none !important;
  outline: none !important;
  height: 80px;
  width: -webkit-fill-available !important;
  padding: 0;
  margin-bottom: 1rem;
  border-bottom: solid 1px #e5e5e6 !important;
  border-radius: 5px;
}

.form-control::placeholder {
  color: #aeaeae;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
}

.msg-form-control::placeholder {
  color: #aeaeae;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
}

.tnc-text {
  color: #aeaeae;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
}

.contactus-form-label {
  margin-left: 0rem;
  color: #aeaeae;
  font-family: "Poppins", Sans-serif;
  font-size: 13px;
}

.contactus-check-label {
  display: flex;
  justify-content: left;
  color: #363B8C;
}

textarea {
  margin-bottom: 0px;
}

.contactus-button-value {
  margin-right: 10px;
  display: flex;
  width: 4rem;
  justify-content: center;
}
.contactus-button-spinner{
  border: 5px solid #f3f3f3;
  border-top: 6px solid rgb(223, 135, 80);
  border-radius: 50%;
  display: flex;
  width: 25px;
  height: 25px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contactus-right-button {
  margin-top: 4rem;
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.contactus-vertical-top {
  border-left: 1px solid #000;
  height: 475px;
  position: absolute;
  margin-left: -20%;
}

.contactus-vertical-top-text {
  transform: translateX(-50%);
}

.home-side-container {
  overflow: hidden;
}

.contactus-mobile-error {
  color: rgb(196, 86, 86);
  font-size: 14px;
  margin-top: -2rem;
  margin-left: 0rem;
  margin-right: 19rem;
  margin-bottom: 2rem;
}

.contactus-error {
  color: rgb(196, 86, 86);
  margin-top: -1rem;
  font-size: 14px;
  margin-bottom: 2rem;
  margin-right: 17rem;
}

.contactus-button-left-text:disabled {
  background-color: #f28c04;
  cursor: not-allowed;
}

.contactus-textarea-error {
  margin-left: 0rem;
  width: 100%;
}

.phone-grid{ 
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 20% 80%;
  margin-top: 2rem;
  /* gap: 10px;  */
}

.contactus-error-ph{
  color: rgb(196, 86, 86);
  /* margin-top: -1rem; */
  font-size: 14px;
  /* margin-bottom: 2rem; */
  margin-right: 17rem;
}

/* .phone-Input-Container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
/* font-size: 16px;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 300px;
    outline: none;
    padding: 18.5px 14px 18.5px 60px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
} */
.phnumo{
  width: 80%;
}

.react-tel-input {
  font-family: 'Roboto',sans-serif;
  font-size: 15px;
  position: relative;
  width: 50% !important;
}

.react-tel-input input {
  width: 101px !important;
}

/* display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30% 68%;
    gap: 10px;
    margin-left: -3.5rem; */

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-control{
  font-size: 13px !important;
}

.phone-input-num {
  width: 100%;
  font-size: 13px;
  /* font-weight: 400; */

  font-family: "Poppins", Sans-serif;
  line-height: 1.5;
    background: #FFFFFF;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 15px 13px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color:black;;
}

/* Simple modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.modal-content {
  position: relative;
  margin: 15% auto;
  padding: 20px;
  background: white;
  max-width: 500px;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.Agreebtn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
      background: linear-gradient(90deg, #ffa707 0%, rgba(253, 77, 29, 1) 20%, rgba(252, 176, 69, 1) 100%);
  color: white;
  border: none;
  border-radius: 5px;
}


@media screen and (max-width: 1300px) {
  .contactus-check-label {
    display: flex;
    justify-content: left;
  }
}

@media screen and (max-width: 1000px) {
  .contactus-image-left {
    margin-left: 10px;
  }
}

@media screen and (max-width: 800px) {
  .contactus-title-right {
    margin-left: -13rem;
  }

  .contactus-divider-separator {
    margin-left: 8rem;
  }

  .phone-grid{ 
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 20% 78%;
    margin-top: 2rem;
     gap: 8px;  
  }

  .md-pd{
    padding: 1rem 0.75rem !important;
  }
}

@media screen and (max-width: 500px) {
  .form-control {
    margin-right: 12rem !important;
    margin-left: -4rem;
  }

  .contactus-form {
    margin-left: 4rem;
  }

  .contactus-right-button {
    margin-left: 4rem;
    justify-content: flex-start;
  }

  .tnc-text-input {
    margin-left: 1rem;
  }
  .terms{
    color: red;
  }

  .contactus-title-right {
    margin-left: 5rem;
  }

  .contactus-divider-separator {
    margin-left: 10rem;
  }

  .contactus-mobile-error {
    margin-right: 9rem;
    margin-left: -4rem;
  }

  .contactus-error {
    margin-right: 8rem;
    margin-left: -4rem;
  }

  .contactus-textarea-error {
    margin-top: -4px;
    margin-left: -3rem;
  }

  .contactus-form-label {
    margin-left: -4rem;
  }

  .phoneInput{
    margin-left: -4.5rem;
    width: 73% !important;
  }

  .phone-grid{ 
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 12% 59%;
    margin-top: 2rem;
    margin-left: -4.7rem;
    gap: 10px;  
  }

  .contact-col{
    margin-left: -3rem;
  }

}