.usp-main-section {
  background-color: wheat;
  padding: 3rem 2em;
}

.usp-title h1 {
  font-size: 3rem;
  color: black;
}

.mainflex{
  display: flex;
}
.usp-subtitle {
  margin: 0;
  color: black;
  font-weight: 400;
  line-height: 2rem;
  font-size: 1rem;
  padding: 16px 0px 48px 0px;
}

/*Card CSS*/
.usp-card {
  opacity: 1 !important;
  color: #d6d6d6;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 40% !important;
  height: 380px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  overflow: hidden;
}

.usp-card-third {
  /* margin-top: 0.6rem !important; */
  width: 76.3% !important;
  height: 375px;
}

.usp-card-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: black;
  line-height: 37px;
  font-size: 1.6875rem;
}

.usp-card-content {
  margin: 0;
  font-weight: 300;
  color: black;
  line-height: 1.1875rem;
  font-size: 1rem;
  padding: 20px 0px 48px 0px;
}

.usp-card-image img {
  width: 150px;
  height: 150px;
}

@media screen and (max-width: 1300px) {
  .usp-card-image img {
    width: 200px;
    height: 175px;
  }
}

@media screen and (max-width: 1000px) {
  .usp-card-image img {
    width: 170px;
    height: 140px;
  }
}

@media screen and (max-width: 1000px) {
  .usp-card-image img {
    width: 170px;
    height: 140px;
  }
}

@media screen and (max-width: 768px) {
  .usp-card-content {
    padding: 16px 0px 28px 0px;
  }
}

@media screen and (max-width: 500px) {
  .usp-card-image img {
    width: 140px;
    height: 140px;
  }
  .usp-title h1 {
    font-size: 50px;
    color: black;
}
}


