.Hovercontent-second-main-div {
  cursor: pointer;
  position: fixed;
  background-color: white;
  top: 75px;
  padding: 1rem;
  width: fit-content;
  height: fit-content;
  left: 15rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 4px;
  margin-left: 20%;
  margin-right: 15%;
}
/* .hovercontent-first-container {
  display: flex;
  justify-content: center;
} */
.hovercontent-first-container a {
  color: #37393c;
  text-decoration: none;
  line-height: 20px;
  padding: 5px;
  width: fit-content;
  font-size: 14px;
  padding-left: 0px;
}
.hovercontent-first-container a:hover {
  /* color: rgb(219, 127, 73); */
  font-size: 14.5px;
  transition: 0.2s all ease-in;
  cursor: alias;
}
.service-modal-heading {
  color: #FE904D;
  font-weight: 550;
  margin-top: 30px;
  margin-bottom: 4px;
  position: relative;
}
.service-modal-heading::after {
  content: "";
  position: absolute;
  width: 50%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(219, 127, 73);
  transform-origin: bottom right;
  transition: transform 0.45s ease-out;
}

.service-modal-heading:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* .service-modal-heading::before {
  content: "";
  position: absolute;
  left: 15%;
  bottom: -5px;
  width: 150px;
  height: 14px;
  transform: skew(-12deg) translateX(-50%);
  background: #f28b0498;
  z-index: -1;
} */

.second-hover-content-first-column,
.second-hover-content-second-column,
.second-hover-content-third-column,
.second-hover-content-fourth-column {
  display: grid;
  color: black;
}
