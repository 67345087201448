.element-button {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 21px;
  fill: #2f2f2f;
  color: #2f2f2f;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #00e9ff;
  border-radius: 3px;
  padding: 11px 17px;
}
