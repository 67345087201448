.cover-main-section {
  /* margin-top: 12rem; */
  padding: 3rem 0;
  background-color: #f5deb3b3 ;
}

.cover-title h1 {
  font-size: 3rem;
  color: #000;
}

.cover-subtitle {
  margin: 0;
  color: #000;
  font-weight: 400;
  line-height: 5rem;
  font-size: 1.5rem;
  padding: 0px 0px 20px 0px;
}

/*Card CSS*/
.cover-card {
  color: #d6d6d6;
  opacity: 1 !important;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 83% !important;
  height: 250px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  overflow: hidden;
}

.cover-card-third {
  margin-top: 0.6rem !important;
  width: 50% !important;
  height: 250px;
}

.cover-card-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: black !important;
  line-height: 30px;
  font-size: 1.6875rem;
}

.cover-card-content {
  margin: 0;
  font-weight: 300;
  color: black;
  line-height: 1.1875rem;
  font-size: 1rem;
  padding: 5px 0px 20px 0px;
}

.cover-card-image img {
  width: 150px;
  height: 100px;
}

@media screen and (max-width: 1300px) {
  .cover-card-image img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .cover-card-image img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .cover-card-image img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .cover-card-content {
    padding: 4px 0px 14px 0px;
  }
}

@media screen and (max-width: 500px) {
  .cover-card-image img {
    width: 140px;
    height: 90px;
  }
}
