.Client-section-div {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  line-height: 3;
}
.client-section-upper-main-div {
  background-color: white;
}
.client-section-main-div {
  display: flex;
  /* justify-content: space-around; */
  height: 40rem;
}
.client-section-first-container {
  width: 45%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  transform: translateX(17%);
  line-height: 1.5rem;
  padding: 37px 34px;
  background: #e8eaf3;
}
.client-section-first-sub-container {
  display: grid;
}
.client-paragraph-content {
  display: flex;
  justify-content: left;
  font-size: 12.5px;
  width: 70% !important;
  line-height: 1.9;
}
.client-paragraph-content-additional {
  display: flex;
  justify-content: left;
  font-size: 12.5px;
}
.client-paragraph-content-hidden {
  display: flex;
  justify-content: left;
  font-size: small;
  width: 64%;
  line-height: 1.9;
}
.first-containers-row {
  display: flex;
  justify-content: flex-start;
}
.vl {
  border-left: 6px solid black;
  height: 5rem;
}
.client-values {
  color: orangered;
  font-size: 22px;
}
.client-paragraph-heading {
  font-size: 1rem;
  padding-top: 5rem;
}
.client-content {
  padding: 0rem 10px;
  line-height: 1;
}
.client-values-hidden {
  font-size: 25px;
  color: orangered;
}

.client-section-second-container {
  width: 45%;
  height: 90%;
  line-height: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 37px 34px;
  transform: translateY(24%);
  background: #eaedf4;
  margin: auto;
}
@media screen and (max-width: 1024px) {
  .client-values {
    font-size: 15px;
  }
  .client-values-hidden {
    font-size: 13px;
}
}

@media screen and (max-width: 600px) {
  .client-section-main-div {
    display: flex;
    justify-content: space-around;
    height: max-content;
    flex-direction: column;
    width: max-content;
  }
  .Client-section-div {
    line-height: 2;
    padding-left: 15%;
  }
  .client-section-first-container {
    width: 15%;
    height: 33%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    transform: translateX(0%);
    line-height: 2.5rem;
    padding: 25px;
    background: wheat;
  }
  .client-section-second-container {
    width: 15%;
    height: 33%;
    line-height: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    transform: translateY(3%);
    padding: 25px;
    background: gainsboro;
  }
  .col-6 {
    flex: 0 0 auto;
    width: 70% !important;
    padding-top: 19px !important;
  }

  .first-containers-row {
    display: flex;
    display: grid;
    gap: 1rem;
    justify-content: flex-start;
  }
  .client-paragraph-content-hidden {
    width: 100%;
  }
}
@media  screen and (max-width: 900px) {
  .client-paragraph-heading {
    font-size: 17px;
    padding-top: 4rem;
  }
}