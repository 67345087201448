.hire-developer-title-left {
    color: #f28c04;
    text-align: left;
    font-family: "Poppins", Sans-serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    margin: 3rem 0rem;
  }
  
  .hire-developer-left-body {
    color: #000;
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    margin-bottom: 3rem;
  }
  
  .hire-developer-left-subbody {
    color: #000;
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
  }

  .sentence::after {
    content: " ➜"; /* Unicode arrow character */
    font-weight: bold; /* Adjust as needed */
    margin-left: 5px; /* Adjust the spacing between the sentence and the arrow */
    color: #FF5733; /* Arrow color */
  }
  
  .arrow-symbol {
    font-size: 1.4em; /* Adjust the font size as needed */
    vertical-align: -0.05em;
  }