.tech-integration-main-div {
  background-color: white;
  padding: 80px;
}

.tech-heading-div {
  display: flex;
  justify-content: center;
  margin-top: 50px; 
}

.tech-heading {
  font-size: 3rem; 
  color: black;
  text-align: center;
  line-height: 1.2; 
}

.tech-paragraph-content {
  color: black;
  font-size: 1rem; 
  line-height: 1.8; 
}

.tech-paragraph-main-div {
  display: grid;
  padding: 60px;
  gap: 1.5rem;
}

.tech-second-column {
  background-image: url(../../../assets/icons.png);
  background-size: contain;
  width: fit-content;
  display: flex;
  justify-content: center;
  font-size: xx-large;
  height: 100vh;
  color: white;
}

.main-content-tech-row {
  display: block;
}

@media only screen and (max-width: 700px) {
  .tech-heading {
    font-size: 2.5rem;
    padding-left: 2.5rem;
    margin-top: 30px; 
  }

  .tech-paragraph-content {
    width: 90vw;
    font-size: 1.1rem; 
  }

  .main-content-row {
    display: flex;
    flex-direction: column;
  }
}
